import { BaseService, axios } from "@pigeonline/core"
import { app } from "@/main.js"

export default class ProfilesService extends BaseService {
  async getProfileLicenses() {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth"
      const response = await axios.get(
        `${URL}/profiles/get-current-licenses`,
        this.getAuthHeader()
      )
      return response.data
    } catch (e) {
      throw new Error("profilesService.js:users " + e.message)
    }
  }
  async getTeamOrUserLicenses(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth"
      const response = await axios.post(
        `${URL}/profiles/get-licenses`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("AdminService.js " + e.message)
    }
  }
}
