<template>
  <span
    v-bind:class="componentName"
    v-bind:id="generatedUID"
    :style="noTranslationStyle"
    class="visible-text__content"
  >
    <template v-if="setLanguage === 'fr' && local_fr.length > 0">
      {{ local_fr }}
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </span>
</template>

<script>
export default {
  name: "VisibleText",
  data() {
    return {
      local_en: "",
      local_fr: ""
    }
  },
  mounted() {
    if (this.$slots && this.$slots.default && this.$slots.default.length) {
      this.local_en = this.$slots.default[0].text
    }
    this.checkCache()
  },
  computed: {
    componentName() {
      return this.$parent.$options.name
    },
    generatedUID() {
      return this.$.uid
    },
    setLanguage() {
      return this.$store.getters.getLanguage
    },
    languageDebugger() {
      return this.$store.getters.getLanguageDebug
    },
    noTranslationStyle() {
      return this.setLanguage === "fr" &&
        this.local_fr.length === 0 &&
        this.languageDebugger === "on"
        ? { background: "red" }
        : null
    }
  },
  methods: {
    checkCache() {
      let filteredCache = this.checkLocalCache(
        this.local_en,
        this.componentName
      )
      if (filteredCache) this.local_fr = filteredCache.text_fr
    }
  }
}
</script>
