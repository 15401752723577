<template>
  <Modal
    header-tag="h1"
    :show="isShowScoreExpanded"
    :resetListener="resetListener"
    :wider-format="true"
    @close="$emit('close')"
  >
    <template #header><VisibleText>Question details</VisibleText></template>
    <template slot="close">
      <form
        class="user-guide__form"
        id="contact_us_do_not_show_form"
        @submit.prevent="close_and_save"
      >
        <ButtonIconOnly
          icon="remove"
          class="modal-close-btn"
          @click-handler="close_and_save"
        >
          <VisibleText>close</VisibleText>
        </ButtonIconOnly>
      </form>
    </template>
    <div style="font-size: 1rem">
      <div v-if="showThisQuestion.question_title">
        <strong><VisibleText>Question title</VisibleText></strong>
        {{ showThisQuestion.question_title }}
      </div>
      <div v-if="showThisQuestion.question_text">
        <strong><VisibleText>Question Text</VisibleText></strong>
        {{ showThisQuestion.question_text }}
      </div>
      <div style="margin-top: 1rem">
        <strong
          ><VisibleText>Unique values in this question:</VisibleText></strong
        >
      </div>
      <ul class="values-ul">
        <li
          v-for="(value, index) in showThisQuestion.unique_values"
          :key="`copy-values-${index}`"
          :class="['values-li']"
        >
          <strong><VisibleText>Value:&nbsp; </VisibleText></strong
          >{{ value.value }}
          <span style="font-size: 0.8rem"
            ><strong><VisibleText>Set as valid:&nbsp; </VisibleText></strong
            >{{ value.is_valid }}</span
          >
        </li>
      </ul>

      <div v-if="showThisQuestion.scale_range">
        <strong><VisibleText>Confirmed data type:&nbsp; </VisibleText></strong
        >{{ showThisQuestion.scale_range }}
      </div>

      <div v-if="showThisQuestion.built_from">
        <strong><VisibleText>Built from:&nbsp; </VisibleText></strong
        >{{ showThisQuestion.built_from }}
      </div>
    </div>

    <!-- <div class="contactUs__wrapper">
      TO DO - The below will not work as it relies on a data range called
      "detailedResults" from "benchmarking service".
      We will need to create a new endpoint where owners can "get" distributions of any scale question, without needing a "report"
      <button @click="getChart">show</button>
       <HorizontalBarChart :value="foundHoriztonalChart" v-if="foundHoriztonalChart && Object.keys(foundHoriztonalChart).length" />
    </div> -->
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import { questionSummaryNodes } from "@/components/Reports/Utils/Library/Benchmarking/benchmarkingQbyQNodes.js"
// import { HorizontalBarChart } from "@pigeonline/pigeondoc"

export default {
  name: "ShowScore",
  components: {
    Modal,
    ButtonIconOnly
  },
  props: {
    status: {
      default: () => false,
      type: Boolean
    },
    showThisQuestion: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      newStatus: false,
      firstLoad: true,
      resetListener: false,
      foundHoriztonalChart: {}
    }
  },
  methods: {
    close_and_save() {
      this.isShowScoreExpanded = false
      this.$emit("close")
    },
    getChart() {
      if (this.showThisQuestion && Object.keys(this.showThisQuestion).length) {
        // TO DO -> create a special endpoint to replace the bleow
        let summarySectionNodes = questionSummaryNodes(
          5.0,
          "test",
          "test",
          this.showThisQuestion.unique_values,
          "EN"
        )
        // The third node is a horizontal bar chart from benchmarking reports
        if (summarySectionNodes.length && summarySectionNodes[3]) {
          this.foundHoriztonalChart = summarySectionNodes[3]
        }
      }
    }
  },
  computed: {
    isShowScoreExpanded: {
      get() {
        return this.newStatus
      },
      set(value) {
        this.newStatus = value
      }
    },
    chartData() {
      if (!this.isShowScoreExpanded) {
        return []
      }
      if (Object.keys(this.showThisQuestion).length) {
        if (this.showThisQuestion.unique_values) {
          return this.showThisQuestion.unique_values
        }
      }
      return []
    }
  },
  watch: {
    status(newVal) {
      this.newStatus = newVal
    }
  }
}
</script>
