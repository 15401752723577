<template>
  <div class="landing-page__wrapper">
    <header
      class="landing-page__hero-section"
      :style="{
        backgroundImage: `url(${require('@/assets/landing/hero.png')})`
      }"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <div class="landing-page__head">
        <img
          class="landing-page__image landing-page__image--left landing-page__image--logo landing-page__image--padded"
          src="/assets/landing/RAI_logo.png"
          alt="Research AI"
        />
        <nav aria-label="primary" class="landing-page__nav">
          <ul class="landing-page__nav-inner">
            <li v-if="!$pigeonline.userIsAuthenticated()">
              <button
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                @click="isBookADemoExpanded = !isBookADemoExpanded"
              >
                <VisibleText>Request a demo</VisibleText>
              </button>
            </li>
            <li v-if="!$pigeonline.userIsAuthenticated()">
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/register"
                style="display: inline-block"
              >
                <VisibleText>Register for free</VisibleText>
              </router-link>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/library"
                style="display: inline-block"
              >
                <VisibleText>Library</VisibleText>
              </router-link>
            </li>
            <li
              class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
              v-show="false"
            >
              <label for="lang-select" class="label">
                <SvgIconDecorative icon="translate" />
                <span class="sr-only"><VisibleText>language</VisibleText></span>
              </label>
              <select
                class="landing-page__select landing-page__font--white landing-page__font--bold"
                id="lang-select"
                @change="changeLanguage($event)"
              >
                <option
                  value="en"
                  :data-parent="$options.name"
                  :selected="currentLang === 'en'"
                >
                  {{ translate("English", $options.name) }}
                </option>
                <option
                  value="fr"
                  :data-parent="$options.name"
                  :selected="currentLang === 'fn'"
                >
                  {{ translate("French", $options.name) }}
                </option>
              </select>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/login"
                style="display: inline-block"
              >
                <VisibleText v-if="$pigeonline.userIsAuthenticated()"
                  >Return to app</VisibleText
                >
                <VisibleText v-else>Log in</VisibleText>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="landing__content-box">
        <div
          class="landing-page__content-box landing-page__content-box--padded-bottom"
        >
          <div class="landing-page__seven landing-page__column-widen">
            <h1
              class="landing-page__font--h1 landing-page__font--white landing-page__font--outline"
            >
              <VisibleText
                >Automate research tasks. Save hours of effort and weeks of
                waiting.
              </VisibleText>
            </h1>
            <div
              class="landing-page__font landing-page__font--dark-blue landing-page__font--right-padding landing-page__font--large"
            >
              <VisibleText
                >Upload your data and access tools like prediction, text
                analysis and more to drive organizational improvement.
              </VisibleText>
            </div>
          </div>
          <div class="landing-page__five">
            <img
              class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
              src="/assets/landing/hero_image.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </header>
    <main
      id="main-content"
      tabindex="-1"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <section>
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText>How it works</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
        >
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              1.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/collect-data_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">Upload your data</h3>
            <p>
              <VisibleText
                >Easily upload data (like surveys, customer/employee management
                systems, live-chats, social media, and more).
              </VisibleText>
            </p>
            <span>
              <VisibleText>Need help getting started?</VisibleText>
              <button
                class="landing-page__button landing-page__button--small"
                @click="isContactUsExpanded = !isContactUsExpanded"
              >
                <VisibleText>Get in touch</VisibleText>
              </button>
            </span>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              2.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/analyze_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Analyze</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Our analysis tools help you find meaningful insights thanks to
                suggestive AI. Detailed results that used to be painstaking can
                now be found in clicks.
              </VisibleText>
            </p>
            <span>
              <VisibleText>Already have data?</VisibleText>
              <router-link
                tag="button"
                to="/register"
                class="landing-page__button landing-page__button--small"
              >
                <VisibleText>Register for free</VisibleText>
              </router-link>
            </span>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              3.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/improve_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Improve</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Quick and easy analysis identifies and ranks what issues need
                attention. Improve objectives like client satisfaction and
                employee engagement.
              </VisibleText>
            </p>
          </div>
        </div>
        <div class="landing-page__content-center">
          <button class="landing-page__button" @click="openVideo('intro')">
            <VisibleText>Watch explainer (1 min)</VisibleText>
          </button>
          <button class="landing-page__button" @click="openVideo('demo')">
            <VisibleText>Watch demo (4 mins)</VisibleText>
          </button>
        </div>
      </section>
      <div class="landing-page__grey_skew"></div>
      <section class="landing-page__grey-section">
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText>Useful for</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__content-box--mobile-flex"
          role="tablist"
          aria-label="Useful for"
        >
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-drivers"
            role="tab"
            :aria-selected="tab_active === 0"
            :class="[
              tab_active === 0
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 0"
          >
            <VisibleText>Customer and employee experience</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-benchmarking"
            role="tab"
            :aria-selected="tab_active === 1"
            :class="[
              tab_active === 1
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 1"
          >
            <VisibleText>Text analysis</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-performance"
            role="tab"
            :aria-selected="tab_active === 2"
            :class="[
              tab_active === 2
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 2"
          >
            <VisibleText>Performance management</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-text"
            role="tab"
            :aria-selected="tab_active === 3"
            :class="[
              tab_active === 3
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 3"
          >
            <VisibleText>Digital literacy</VisibleText>
          </button>
        </div>
        <div
          v-show="tab_active === 0"
          aria-labelledby="tab-drivers"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 0"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <h3 class="landing-page__font--h3--large">
              <VisibleText>Customer and employee experience</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Data can help an organization unlock customer/employee success.
                Upload survey or text data and unlock the ability to:
              </VisibleText>
            </p>
            <h4>
              <VisibleText> Predict improvement </VisibleText>
            </h4>

            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Know exactly what aspects of an experience are 'driving'
                    satisfaction, churn, purchase, loyalty, engagement, or other
                    business outcome.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Prioritize top predictors and track progress over time
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Identify drivers that may be unique for different segments.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_cxdrivers')"
                >
                  <img
                    style="width: 50%"
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_cxdrivers.png"
                    alt="Screenshot of customer experience drivers"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>

            <h4>
              <VisibleText> Track segments and journey </VisibleText>
            </h4>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Track the entire journey of a customer or employee
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Benchmark sectors or departments against each other
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Detect important 'outliers' in your service experience, such
                    as specific groups that are particularly upset.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_cx-outliers')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_cx-outliers.png"
                    alt="Screenshot of segmentation outliers"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>

            <h4>
              <VisibleText>
                Unlock the voice of the customer or employee
              </VisibleText>
            </h4>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Use open-ended surveys to build customer or employee
                    measures that actually matter to them.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Capture important signals about your organization from
                    already-available text data, like social media.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Go beyond satisfaction: understand emotions, sentiment, and
                    organic trends.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_suggested-themes-2')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_suggested-themes.png"
                    alt="Screenshot of auto-generation thematic analysis"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tab_active === 1"
          aria-labelledby="tab-benchmarking"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 1"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <div style="display: flex; flex-direction: row">
              <h3 class="landing-page__font--h3--large">
                <VisibleText>Text analysis</VisibleText>
              </h3>
              <span>
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openVideo('text-analysis')"
                >
                  <SvgIconDecorative icon="video" />
                  <VisibleText style="font-weight: 500"
                    >Watch text analysis video</VisibleText
                  >
                </button>
              </span>
            </div>
            <p>
              <VisibleText
                >Many of us are sitting on large amounts of text data but do not
                know how to make use of it. Change the way you look at surveys,
                live-chats, social media, or complaints:
              </VisibleText>
            </p>
            <h4>
              <VisibleText> Get suggested themes </VisibleText>
            </h4>

            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Using the most common text AI (Natural Langauge Processing),
                    get suggested themes based on interesting keywords.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Build your own text models or find themes built from text
                    models built and managed by leading HR and management
                    researchers.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_suggested-themes')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_suggested-themes.png"
                    alt="Screenshot of auto-generation thematic analysis"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
            <h4>
              <VisibleText> Sort and arrange themes </VisibleText>
            </h4>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Coverage scores tell you precisely how often a theme is
                    mentioned.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Sentiment scores suggest how negative or positive
                    respondents are across your themes.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_text-theme-sentiment')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_text-theme-sentiment.png"
                    alt="Screenshot of text analysis coverage and sentiment"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column; width: 95%"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <p>
                    <VisibleText style="font-weight: 800">
                      Read more:
                    </VisibleText>
                    <SvgIconDecorative icon="externalLink" />
                    <a
                      href="http://research-ai.io/library/guide/what-is-thematic-analysis-in-researchai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Our guide to text analysis, sentiment analysis, and
                      building your own text models.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tab_active === 2"
          aria-labelledby="tab-performance"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 2"
          style="flex-direction: column"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <h3 class="landing-page__font--h3--large">
              <VisibleText>Performance management</VisibleText>
            </h3>
            <p>
              <VisibleText>
                Organizations have more data than just surveys. Consider one of
                our airport partners that collected 3 months worth of
                operational data from 30,000 passengers:
              </VisibleText>
            </p>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Tracked the customer journey: airline, gate, restaurants,
                    and demographics, finding specific bottlenecks.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Predicted specific drivers of operational success, such as
                    what led to passengers purchasing in restaurants and what
                    detracted from this.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_airport-outliers')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_airport-outliers.png"
                    alt="Screenshot of outliers analysis"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
            <p>
              <VisibleText>
                ResearchAI also makes benchmarking easy. Many organizations
                would like to compare specific sectors, business units, or
                locations in terms of operational and customer success.
                ResearchAI automates detailed bencmarking reports in clicks.
              </VisibleText>
            </p>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Build your own benchmarking database by setting some
                    standard questions. It then becomes easy to connect datasets
                    to this benchmarking database.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Connect your data to already-established benchmarking
                    databases available in the system, such as the Institute for
                    Citizen-Centred Service's Public Sector Benchmarking.

                    <router-link
                      to="/citizenfirstanalyticss"
                      style="display: inline-block"
                    >
                      <VisibleText>Learn more here.</VisibleText>
                    </router-link>
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_benchmarking-report')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_benchmarking-report.png"
                    alt="Screenshot of benchmarking analysis"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column; width: 95%"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <p>
                    <VisibleText style="font-weight: 800">
                      Read more:
                    </VisibleText>
                    <SvgIconDecorative icon="externalLink" />
                    <a
                      href="https://research-ai.io/library/guide/what-is-benchmarking-in-researchai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Our guide to benchmarking analysis, including how to build
                      your own benchmarking databases.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tab_active === 3"
          aria-labelledby="tab-text"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 3"
        >
          <div class="landing-page__font">
            <h3 class="landing-page__font--h3--large">
              <VisibleText>Digital literacy</VisibleText>
            </h3>
            <p>
              <VisibleText>
                Many organizations are looking to assess their digital maturity
                and begin integrating data into their decision-making for the
                first time. Others are looking for ways to introduce artificial
                intelligence into the workforce but are not really sure what it
                means or what executives/managers should know.
              </VisibleText>
            </p>
            <p>
              <VisibleText>
                ResearchAI has been a useful no-code learning tool for
                universities, governments, and businesses. This has been done
                through executive learning, workshops and consultative webinars.
              </VisibleText>
            </p>
            <ul>
              <li>
                Recognize the basic anatomy of data, algorithms, and what
                matters the most to managers and executives.
              </li>
              <li>
                Prediction tools utilizing new and old regression techniques no
                longer require syntax, code, SPSS, or r-statistics.
              </li>
              <li>
                Text is understood by algorithms differently than humans. Learn
                how computers use text and related opportunities and risks (such
                as bias) this introduces.
              </li>
            </ul>
            <div
              style="display: flex; flex-direction: column; width: 95%"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 400"
                >
                  <p>
                    <VisibleText>
                      We have helped organizations big and small in developing a
                      data strategy. Set up a no-cost workshop dedicated to
                      digital literacy for executives or managers.
                    </VisibleText>
                  </p>
                  <button
                    class="landing-page__button landing-page__button--light-blue--active"
                    @click="isBookAWorkshopExpanded = !isBookAWorkshopExpanded"
                  >
                    <VisibleText>Book a workshop</VisibleText>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="landing-page__hero-section landing-page__hero-section--padded-top landing-page__hero-section--mobile-fit-height"
        :style="{
          backgroundImage: `url(${require('@/assets/landing/blue_section.png')})`
        }"
      >
        <h2
          class="landing-page__font--h2 landing-page__font--center landing-page__font--white landing-page__font--outline"
        >
          <VisibleText>How to get started</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__font landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
        >
          <div
            class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
          >
            <p style="text-align: left">
              <VisibleText
                >If you already have data, why not get started for free
              </VisibleText>
            </p>
            <div style="display: flex; align-items: center">
              <div
                class="item search rounded-pill landing-page__font landing-page__shorter-button"
                style="background-color: var(--bali-hai-blue); margin: auto"
              >
                <VisibleText> Free license </VisibleText>
              </div>
            </div>
            <ul style="text-align: left">
              <li>
                <VisibleText>
                  All data cleaning, analysis and reporting features
                </VisibleText>
              </li>
              <li>
                <VisibleText>
                  Will only count the first 150 rows of any dataset
                </VisibleText>
              </li>
            </ul>
            <router-link
              class="landing-page__button landing-page__button--small-dark"
              to="/register"
              style="display: inline-block"
            >
              <VisibleText>Register</VisibleText>
            </router-link>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
          >
            <p style="text-align: left">
              <VisibleText
                >Are you ready to lead your organization through digital
                success?
              </VisibleText>
            </p>
            <div style="display: flex; align-items: center">
              <div
                class="item search rounded-pill landing-page__font landing-page__shorter-button"
                style="
                  background-color: var(--antique-yellow);
                  margin: auto;
                  color: white;
                "
              >
                <VisibleText> Organizational license </VisibleText>
              </div>
            </div>
            <ul style="text-align: left">
              <li>
                <VisibleText>
                  All data cleaning, analysis and reporting features
                </VisibleText>
              </li>
              <li>
                <VisibleText> No row limits </VisibleText>
              </li>
              <li>
                <VisibleText>
                  Organizational management and team collaboration features
                </VisibleText>
              </li>
            </ul>
            <button
              class="landing-page__button"
              @click="isContactUsExpanded = !isContactUsExpanded"
            >
              <VisibleText>Request an upgrade</VisibleText>
            </button>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
            v-if="AB === 0"
          >
            <p style="text-align: left">
              <VisibleText
                >No data? Looking to develop your first digital strategy?
              </VisibleText>
            </p>
            <div style="display: flex; align-items: center">
              <div
                class="item search rounded-pill landing-page__font landing-page__shorter-button"
                style="background-color: var(--shilo-red); margin: auto"
              >
                <VisibleText> Consultative support </VisibleText>
              </div>
            </div>

            <ul style="text-align: left">
              <li>
                <VisibleText>
                  Free 45 minute workshop based on accredited executive learning
                </VisibleText>
              </li>
              <li>
                <VisibleText>
                  Dedicated data transformation success manager to help with
                  maturity audit and strategic roadmap tailored to your data and
                  customer needs.
                </VisibleText>
              </li>
            </ul>
            <button
              class="landing-page__button landing-page__button--light-blue--active"
              @click="isBookAWorkshopExpanded = !isBookAWorkshopExpanded"
            >
              <VisibleText>Book a workshop</VisibleText>
            </button>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
            v-if="AB === 1"
          >
            <p style="text-align: left">
              <VisibleText>Would you like to learn more? </VisibleText>
            </p>
            <div style="display: flex; align-items: center">
              <div
                class="item search rounded-pill landing-page__font landing-page__shorter-button"
                style="background-color: var(--shilo-red); margin: auto"
              >
                <VisibleText> Request a demo </VisibleText>
              </div>
            </div>
            <ul style="text-align: left">
              <li>
                <VisibleText>
                  In 15 minutes we can show you everything you need to know
                  about the tool
                </VisibleText>
              </li>
            </ul>
            <button
              class="landing-page__button landing-page__button--light-blue--active"
              @click="isBookADemoExpanded = !isBookADemoExpanded"
            >
              <VisibleText>Book a demo</VisibleText>
            </button>
          </div>
        </div>
      </section>
      <section class="landing-page__section--padded-top">
        <h2
          class="landing-page__font--h2 landing-page__font--center landing-page__header-in-padded-top"
        >
          <VisibleText>Why we're different</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__font landing-page__content-box--mobile-flexcolumn"
        >
          <div
            class="landing-page__three landing-page__shadow landing-page__shadow--add-margin landing-page__font--center landing-page__three--mobile-widen"
          >
            <h3 class="landing-page__font--h3">
              <VisibleText>Privacy-first</VisibleText>
            </h3>
            <p>
              <VisibleText
                >ResearchAI's insights engine was built from the ground-up with
                privacy in mind. We do not use any public clouds, and we cherish
                the importance of encryption, right to be forgotten, and data
                sovereignty.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__shadow--add-margin landing-page__font--center landing-page__three--mobile-widen"
          >
            <h3 class="landing-page__font--h3">
              <VisibleText>A focus on Return on Investment</VisibleText>
            </h3>
            <p>
              <VisibleText
                >We focus on an out-of-box solution that is easy to set up and
                use. You don't pay until you see meaningful results.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__shadow--add-margin landing-page__font--center landing-page__three--mobile-widen"
          >
            <h3 class="landing-page__font--h3">
              <VisibleText>Supportive AI</VisibleText>
            </h3>
            <p>
              <VisibleText
                >A growing collection of machine learning algorithms are
                designed to assist you and give you more control, not less.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__shadow--add-margin landing-page__font--center landing-page__three--mobile-widen"
          >
            <h3 class="landing-page__font--h3">
              <VisibleText>Platform for solving business problems</VisibleText>
            </h3>
            <p>
              <VisibleText
                >We work with leading practitioners to bring together the best
                models solving business challenges. We are a trusted partner for
                the Canadian Public Sector.
              </VisibleText>
            </p>
          </div>
        </div>
      </section>
      <section
        class="landing-page__section--padded-top landing-page__section--less-padded-top"
      >
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText>Learn more about how we are helping:</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
        >
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <h3 class="landing-page__font--h3">
              <VisibleText> Governments </VisibleText>
            </h3>
            <p>
              <VisibleText>
                We have partnered with the Institute for Citizen-Centred Service
                to create a platform for public sector service excellence.
              </VisibleText>
            </p>
            <router-link
              class="landing-page__button landing-page__button--small-dark"
              to="/citizenfirstanalytics"
              style="display: inline-block"
            >
              <VisibleText>Learn more</VisibleText>
            </router-link>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <h3 class="landing-page__font--h3">
              <VisibleText> Academics </VisibleText>
            </h3>
            <p>
              <VisibleText>
                Our partnerships with academia helps us validate the ways we are
                advancing research, especially text analysis.
              </VisibleText>
            </p>
            <router-link
              class="landing-page__button landing-page__button--small-dark"
              to="/academia"
              style="display: inline-block"
            >
              <VisibleText>Learn more</VisibleText>
            </router-link>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <h3 class="landing-page__font--h3">
              <VisibleText> The Middle East and North Africa </VisibleText>
            </h3>
            <p>
              <VisibleText>
                We have a special connection to the MENA region.
              </VisibleText>
            </p>
            <router-link
              class="landing-page__button landing-page__button--small-dark"
              to="/MENA"
              style="display: inline-block"
            >
              <VisibleText>Learn more</VisibleText>
            </router-link>
          </div>
        </div>
      </section>
      <section
        class="landing-page__grey-section landing-page__grey-section--margin-top"
      >
        <div
          aria-label="use-cases-heading"
          aria-describedby="carousel-sr-instructions"
          class="landing-page__carousel-wrapper"
          role="region"
          tabindex="0"
          @focus="onCarouselFocus"
        >
          <h2
            class="landing-page__font--h2 landing-page__font--center"
            id="use-cases-heading"
          >
            <VisibleText>Use cases</VisibleText>
          </h2>
          <ul class="landing-page__carousel" :style="carousel_transform">
            <li class="landing-page__carousel__item">
              <div
                class="landing-page__split-content landing-page__split-content--left"
              >
                <h3
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText
                    >Canadian Province - Vehicle and fleet management agency
                  </VisibleText>
                </h3>
                <div
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText>Saved $20,000 instantly</VisibleText>
                </div>
              </div>
              <div
                class="landing-page__split-content landing-page__split-content--right"
              >
                <p class="landing-page__font landing-page__font--padded">
                  <VisibleText
                    >A manager of this agency ran a quick survey prior to making
                    an investment to improve a customer waiting center. Using
                    ResearchAI they found that these investments would not
                    improve satisfaction and that they were outperforming their
                    peers.
                  </VisibleText>
                </p>
              </div>
            </li>
            <li class="landing-page__carousel__item">
              <div
                class="landing-page__split-content landing-page__split-content--left"
              >
                <h3
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText>Canadian Province - Service center</VisibleText>
                </h3>
                <div
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText
                    >30,000 customers' performance reported in 1/10th the time
                    and effort
                  </VisibleText>
                </div>
              </div>
              <div
                class="landing-page__split-content landing-page__split-content--right"
              >
                <p class="landing-page__font landing-page__font--padded">
                  <VisibleText
                    >The management team of one of Canada's largest service
                    centers have been keen to upgrade their strategic service
                    performance system. What used to take weeks to build reports
                    across multiple service centers can now be done in days.
                  </VisibleText>
                </p>
              </div>
            </li>
            <li class="landing-page__carousel__item">
              <div
                class="landing-page__split-content landing-page__split-content--left"
              >
                <h3
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText
                    >Institute for Citizen-Centred Services
                  </VisibleText>
                </h3>
                <div
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText
                    >Digitized a benchmarking service allowing it to be done in
                    clicks instead of weeks.
                  </VisibleText>
                </div>
              </div>
              <div
                class="landing-page__split-content landing-page__split-content--right"
              >
                <p
                  class="landing-page__font landing-page__font--padded landing-page__font--black"
                >
                  <a
                    class="landing-page__font--black"
                    href="https://citizenfirst.ca/"
                    ref="carousel-iccs-link"
                    tabindex="-1"
                  >
                    ICCS
                  </a>
                  <VisibleText>
                    hosts the largest public sector benchmarking service
                    allowing managers to compare their performance to peers. It
                    used to be a manual process, but now ICCS partners can
                    self-serve their benchmarking in minutes.
                  </VisibleText>
                </p>
              </div>
            </li>
          </ul>
          <div class="landing-page__content-center">
            <button @click="nextCarousel" class="landing-page__button">
              <VisibleText>Next case</VisibleText>
            </button>
          </div>
        </div>
        <span class="sr-only" aria-live="polite">
          {{ carousel_curr_item }}
        </span>
      </section>
      <p id="carousel-sr-instructions" hidden>
        <VisibleText
          >Use the next case button to navigate between slides
        </VisibleText>
      </p>
      <section
        class="landing-page__hero-section landing-page__hero-section--padded-top landing-page__hero-section--increase-background"
        :style="{
          backgroundImage: `url(${require('@/assets/landing/blue_section2.png')})`
        }"
      >
        <div class="landing-page__content-box">
          <div class="landing-page__seven">
            <img
              class="landing-page__image landing-page__image--inherit-width landing-page__image--inherit-width--small-pad landing-page__image--reverse-margin-top"
              src="/assets/landing/hero_image2.png"
              alt=""
            />
          </div>
          <div class="landing-page__five landing-page--widen">
            <div class="landing-page__shadow" style="padding-bottom: 3em">
              <h2 class="landing-page__font--h2" style="padding-bottom: 1em">
                <VisibleText>What problems can we help you solve?</VisibleText>
              </h2>
              <button
                class="landing-page__button"
                @click="isContactUsExpanded = !isContactUsExpanded"
              >
                <VisibleText>Get in touch</VisibleText>
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer :aria-hidden="modalOpen" :inert="modalOpen">
      <div class="landing-page__content-center">
        <h2 class="landing-page__font--h3">
          <VisibleText>Contact us</VisibleText>
        </h2>
      </div>
      <div class="landing-page__content-box landing-page__content-box--center">
        <div>
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/mail_icon.png"
            alt=""
          />
          <div style="display: inline-block" class="landing-page__font">
            <div class="landing-page__font--blue">
              <VisibleText>Email</VisibleText>
            </div>
            <div>hello@researchai.io</div>
          </div>
        </div>
        <a href="https://www.linkedin.com/company/25166569/" target="_blank">
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/linkedin.png"
            alt=""
          />
          <span style="display: inline-block" class="landing-page__font">
            <span
              class="landing-page__footer-text-block landing-page__font--blue"
            >
              LinkedIn
            </span>
            <span
              class="landing-page__footer-text-block landing-page__font--black landing-page__font--underline"
            >
              <VisibleText>Follow us!</VisibleText>
              <SvgIconDecorative icon="externalLink" style="width: 1rem" />
            </span>
          </span>
          <span class="sr-only">
            <VisibleText>(opens in a new tab)</VisibleText>
          </span>
        </a>
      </div>
      <div
        class="landing-page__content-box landing-page__font landing-page__padded-footer"
      >
        <VisibleText>Copyright © 2024 PigeonLine Inc.</VisibleText>
        <div
          class="landing-page__six landing-page__font landing-page__font--right landing-page__font--margined-top"
        >
          <router-link to="/library/privacy-policy">
            <VisibleText>PRIVACY POLICY</VisibleText>
          </router-link>
          |
          <router-link to="/library/ethics-policy">
            <VisibleText>ETHICS POLICY</VisibleText>
          </router-link>
        </div>
      </div>
    </footer>
    <ContactUs
      v-bind:status="isContactUsExpanded"
      @close="isContactUsExpanded = false"
    ></ContactUs>
    <BookAWorkshop
      v-bind:status="isBookAWorkshopExpanded"
      @close="isBookAWorkshopExpanded = false"
    ></BookAWorkshop>
    <BookADemo
      v-bind:status="isBookADemoExpanded"
      @close="isBookADemoExpanded = false"
    ></BookADemo>
    <VideoClip
      v-bind:status="isVideoClipExpanded"
      :videoShortURL="videoShortURL"
      @close="isVideoClipExpanded = false"
    ></VideoClip>
    <ScreenshotGuide
      :status="isScreenshotExpanded"
      :thisScreenshot="thisScreenshot"
      @close="isScreenshotExpanded = false"
    >
    </ScreenshotGuide>
  </div>
</template>

<script>
import { app } from "@/main.js"
import ContactUs from "@/components/ContactUs/ContactUs.vue"
import BookAWorkshop from "@/components/ContactUs/BookAWorkshop.vue"
import BookADemo from "@/components/ContactUs/BookADemo.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import VideoClip from "@/components/VideoClip/VideoClip.vue"
import ScreenshotGuide from "@/components/UserGuide/ScreenshotGuide.vue"
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
import { mapActions } from "vuex"

export default {
  name: "landing",
  components: {
    ContactUs,
    BookAWorkshop,
    BookADemo,
    SvgIconDecorative,
    VideoClip,
    ScreenshotGuide
  },
  setup() {
    const siteData = reactive({
      title: app.config.globalProperties.$theme.theme.pageTitle + "| About",
      description: app.config.globalProperties.$theme.theme.appDescription
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  data() {
    return {
      AB: 0,
      tab_active: 0,
      carousel_active: 1,
      carousel_transform: null,
      carousel_curr_item: null,
      transition_class: null,
      isContactUsExpanded: false,
      isBookAWorkshopExpanded: false,
      isBookADemoExpanded: false,
      isVideoClipExpanded: false,
      isScreenshotExpanded: false,
      thisScreenshot: "",
      videoShortURL: ""
    }
  },
  created() {
    if (window.localStorage.getItem("apiLanguage"))
      this.setLanguage(window.localStorage.getItem("apiLanguage"))
    this.AB = this.applyAB()
  },
  computed: {
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    }
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageDebug"]),
    changeLanguage(language) {
      this.setLanguage(language.target.value)
      window.localStorage.setItem("apiLanguage", language.target.value)
    },
    currentLang() {
      return (
        this.$store.getters["getLanguage"] ||
        window.localStorage.getItem("apiLanguage")
      )
    },
    onCarouselFocus() {
      // set to currently active carousel item
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    nextCarousel() {
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      if (this.carousel_active !== length) {
        let shift_amount = 130 * this.carousel_active
        this.carousel_transform =
          "transform: translateX(-" + shift_amount + "%);"
        this.carousel_active = this.carousel_active + 1
      } else {
        this.carousel_active = 1
        this.carousel_transform = "transform: translateX(0);"
      }

      // set tabindex of ICCS link
      if (this.carousel_active === 3) {
        this.$refs["carousel-iccs-link"].setAttribute("tabindex", "0")
      } else {
        this.$refs["carousel-iccs-link"].setAttribute("tabindex", "-1")
      }

      // curr item text for sr
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    openVideo(shortUrl) {
      this.videoShortURL = shortUrl
      this.isVideoClipExpanded = !this.isVideoClipExpanded
    },
    openScreenshot(screenshot) {
      this.thisScreenshot = screenshot
      this.isScreenshotExpanded = true
    },
    applyAB() {
      // return Math.random() > 0.5 ? 1 : 0
      // Deactivated as no one was requesting workshops
      return 1
    }
  }
}
</script>
