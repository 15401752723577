import { BaseService, axios } from "@pigeonline/core"
import { app } from "@/main.js"

export default class ProfilesService extends BaseService {
  async platformQuery() {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/admin"
      const response = await axios.get(`${URL}/get-platform-analytics`, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:datasets " + e.message)
    }
  }
  async userQuery(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/admin"
      const response = await axios.post(`${URL}/user-query`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("AdminService.js " + e.message)
    }
  }
  async getUserAnalytics(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/admin"
      const response = await axios.post(`${URL}/get-user-analytics`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("AdminService.js " + e.message)
    }
  }
  async getAllTeams(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/admin"
      const response = await axios.post(`${URL}/get-all-teams`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("AdminService.js " + e.message)
    }
  }
  async addLicense(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/admin"
      const response = await axios.post(`${URL}/add-license`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async deleteLicense(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/admin"
      const response = await axios.post(`${URL}/delete-license`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }

  async verifyUserEmail(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/admin"
      const response = await axios.post(`${URL}/verify-user-email`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
}
