<template>
  <div class="account-profile__content">
    <section v-if="profile">
      <h1 class="account-profile__organization-title title">
        <VisibleText>Edit your profile</VisibleText>
        <small class="text-muted"><VisibleText>Profile</VisibleText></small>
      </h1>
      <p v-if="!profileDetails.fullname.original.length">
        <VisibleText
          >Please provide a name as this will be helpful for us supporting you,
          and for team functions.
        </VisibleText>
        <span class="required-indicator"> * </span>
      </p>
      <form
        novalidate
        style="display: flex; width: 40%"
        v-on:submit.prevent="saveNameChange"
      >
        <label
          for="teamname"
          style="width: 10rem; margin-top: 1rem"
          class="form-label"
        >
          <VisibleText>Name </VisibleText>
          <span
            v-if="!profileDetails.fullname.original.length"
            class="required-indicator"
          >
            *
          </span>
        </label>
        <input
          id="fullname"
          v-model="profileDetails.fullname.new"
          type="text"
          class="account-profile__form-input"
          required
          ref="full-name"
          autocomplete="on"
          style="width: 30rem"
        />
        <div style="display: flex; width: 10rem">
          <div
            style="
              display: flex;
              margin: 0.5rem 0 0 1rem;
              align-items: flex-start;
            "
            v-if="!disableSubmit"
          >
            <ButtonIconOnly
              icon="check"
              class="inline-edit-text__action-btn"
              @click-handler="saveNameChange"
            >
              <VisibleText>save change</VisibleText>
            </ButtonIconOnly>
            <ButtonIconOnly
              icon="remove"
              class="inline-edit-text__action-btn"
              @click-handler="cancelNameChange"
            >
              <VisibleText>cancel change</VisibleText>
            </ButtonIconOnly>
          </div>
        </div>
      </form>

      <div
        style="
          display: flex;
          width: 40%;
          flex-direction: row;
          align-items: flex-start;
        "
      >
        <label
          for="email"
          class="form-label"
          style="width: 10rem; display: flex"
        >
          <VisibleText>Email </VisibleText>
        </label>
        <input
          id="email"
          v-model="email"
          type="text"
          class="account-profile__form-input"
          ref="email"
          disabled
          style="width: 30rem"
        />
        <div style="display: flex; width: 10rem"></div>
      </div>
    </section>
    <section id="teams">
      <h1 class="account-profile__organization-title title">
        <VisibleText>Your teams</VisibleText><span> </span>
        <small class="text-muted"><VisibleText>Teams</VisibleText></small>
      </h1>
      <div
        v-if="localTeams && !localTeams.length"
        class="account-profile__organization-admin-options"
      >
        <VisibleText
          >You are not a member of a team and you do not have team
          invites</VisibleText
        >.
      </div>

      <div
        v-if="licenses.length"
        class="account-profile__organization-admin-options"
      >
        <TeamsCardSmall
          v-for="(team, index) in localTeams"
          :key="'team-button' + index"
          :team="team"
          :email="email"
          :isSelected="team == selectedTeam[0]"
          @changeSelectedTeam="changeSelectedTeam(team)"
        >
        </TeamsCardSmall>
        <div v-if="permissions.includes('accounts-create-team')">
          <div
            style="
              height: auto;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            v-if="isBasic"
          >
            <ButtonIconOnly
              @click-handler="addNewTeam"
              icon="add"
              class="inline-edit-text__action-btn"
              style="color: black; display: flex; margin-top: 1rem"
              v-if="!addingNewTeam"
            >
              <VisibleText>Create team</VisibleText>
            </ButtonIconOnly>
            <ButtonIconOnly
              @click-handler="cancelAddNewTeam"
              icon="remove"
              class="inline-edit-text__action-btn"
              style="color: black; display: flex; margin-top: 1rem"
              v-if="addingNewTeam"
            >
              <VisibleText>Cancel adding new team</VisibleText>
            </ButtonIconOnly>
          </div>
        </div>
      </div>
      <div v-if="loadingNewTeam" style="margin-top: 1rem">
        <span class="also-found__loading-spinner spinner"></span>
      </div>

      <div
        v-if="addingNewTeam && !loadingNewTeam"
        class="form-label"
        style="
          border: 1px solid var(--outline-colour);
          padding: 1rem;
          width: 50%;
          border-radius: 0.5em;
          background-color: var(--base-lighter-ui-colour);
          margin-left: 2rem;
          margin-top: 1rem;
        "
      >
        <h2 class="account-profile__organization-title title">
          <VisibleText>Creating new team</VisibleText>
        </h2>
        <div style="display: flex; flex-direction: row">
          <form
            class="account-profile__form"
            style="display: flex"
            novalidate
            v-on:submit.prevent="createNewTeam()"
          >
            <label
              for="teamname"
              style="width: 10rem; margin-top: 1rem"
              class="form-label"
            >
              <VisibleText>Team name </VisibleText>
              <span class="required-indicator"> * </span>
            </label>
            <input
              aria-describedby="full-name-err"
              :id="'new-team-name'"
              v-model="newTeamName"
              type="text"
              class="account-profile__form-input"
              required
              ref="new-team-name"
              style="background-color: white"
            />
          </form>
          <div
            style="display: flex; margin: 0.5rem 0 0 1rem"
            v-if="showNewTeamSave()"
          >
            <ButtonIconOnly
              icon="add"
              class="inline-edit-text__action-btn"
              @click-handler="createNewTeam()"
            >
              <VisibleText>create team</VisibleText>
            </ButtonIconOnly>
          </div>
        </div>
      </div>
      <div v-if="selectedTeam.length">
        <TeamsCardLarge
          v-for="(team, team_index) in selectedTeam"
          :key="'team-card' + team_index + team.last_modified.$date"
          class="form-label"
          style="
            border: 1px solid var(--outline-colour);
            padding: 1rem;
            width: 50%;
            border-radius: 0.5em;
            background-color: var(--base-lighter-ui-colour);
            margin-left: 2rem;
            margin-top: 1rem;
          "
          :team="team"
          :teamIndex="getTeamIndex(team)"
          :email="email"
          @resetTheseTeams="resetTheseTeams"
          @deleteThisTeam="deleteThisTeam"
        >
        </TeamsCardLarge>
      </div>
    </section>
    <section id="licenses">
      <h1 class="account-profile__organization-title title">
        <VisibleText>Your current licenses</VisibleText>
        <small class="text-muted"><VisibleText>Licenses</VisibleText></small>
      </h1>
      <!-- Get working then move changeSelected to mixin -->
      <div
        v-if="licenses.length"
        class="account-profile__organization-admin-options"
      >
        <LicenseCardSmall
          v-for="(license, index) in licenses"
          :key="'license-button' + index"
          @changeSelectedLicense="changeSelectedLicense(license)"
          :isSelected="license == selectedLicense[0]"
          :license="license"
        >
        </LicenseCardSmall>
      </div>
      <div>
        <LicenseCardLarge
          v-for="(license, index) in selectedLicense"
          :key="'license-card' + index + license._id.$oid"
          class="form-label"
          style="
            border: 1px solid var(--outline-colour);
            background-color: var(--base-lighter-ui-colour);
            width: 24rem;
            height: 12rem;
            border-radius: 1rem;
            margin-left: 2rem;
            margin-top: 1rem;
          "
          :license="license"
        >
        </LicenseCardLarge>
      </div>
    </section>
  </div>
</template>

<script>
import UserMixin from "@/utils/mixins/userMixin"
import UserCardsMixin from "@/components/UI/UserCards/userCardsMixin"
import TeamsService from "@/services/teamsService.js"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import LicenseCardSmall from "@/components/UI/UserCards/LicenseCardSmall.vue"
import LicenseCardLarge from "@/components/UI/UserCards/LicenseCardLarge.vue"
import TeamsCardSmall from "@/components/UI/UserCards/TeamsCardSmall.vue"
import TeamsCardLarge from "@/components/UI/UserCards/TeamsCardLarge.vue"

export default {
  name: "accountProfile",
  mixins: [UserMixin, UserCardsMixin],
  components: {
    ButtonIconOnly,
    LicenseCardSmall,
    LicenseCardLarge,
    TeamsCardSmall,
    TeamsCardLarge
  },
  data() {
    return {
      TEAMS_SERVICE: new TeamsService(this.$pigeonline),
      profileDetails: {
        fullname: {
          original: "",
          new: ""
        }
      },
      addingNewTeam: false,
      newTeamName: "",
      loadingNewTeam: false
    }
  },
  computed: {
    disableSubmit: function () {
      let disabled = false

      if (
        Object.values(this.profileDetails).every((v) => v.original === v.new)
      ) {
        disabled = true
      }
      if (!this.profileDetails.fullname.new.length) {
        disabled = true
      }
      return disabled
    },

    isBasic() {
      if (
        this.licenses.filter(
          (license) => this.getLicenseType(license) == "Basic"
        )
      ) {
        return true
      }
      return false
    }
  },
  async mounted() {
    this.$watch(
      "profile",
      function (newVal) {
        if (!newVal) return
        this.setValue("fullname", newVal.fullname)
      },
      { deep: true, immediate: true }
    )
  },

  async created() {
    this.localTeams = this.teams // This sets userCardMixin.js
  },

  methods: {
    setValue(property, val) {
      if (val) {
        this.profileDetails[property].original = val
        this.profileDetails[property].new = val
      }
    },
    async saveNameChange() {
      this.setLoading(true)
      const profile = await this.$pigeonline.profile.save({
        fullname: this.profileDetails.fullname.new
      })
      this.setProfile(profile)
      this.setLoading(false)
    },
    cancelNameChange() {
      this.profileDetails.fullname.new = this.profileDetails.fullname.original
    },

    addNewTeam() {
      this.addingNewTeam = true
      this.selectedTeam = []
    },
    cancelAddNewTeam() {
      this.addingNewTeam = false
      this.selectedTeam = []
    },
    async createNewTeam() {
      this.loadingNewTeam = true
      if (this.newTeamName.length) {
        await this.TEAMS_SERVICE.addTeam({
          team_name: this.newTeamName
        })
        this.selectedTeam = []
        this.addingNewTeam = false
        await this.resetTheseTeams()

        this.localTeams = this.teams
        this.loadingNewTeam = false
      }
    },
    showNewTeamSave: function () {
      let show = false
      if (this.newTeamName.length) {
        show = true
      }
      return show
    }
  }
}
</script>
