<template>
  <Modal
    header-tag="h1"
    :show="isBookADemoExpanded"
    :resetListener="resetListener"
    :wider-format="true"
    @close="$emit('close')"
  >
    <template #header><VisibleText>Book a demo</VisibleText></template>
    <template slot="close">
      <form
        class="user-guide__form"
        id="contact_us_do_not_show_form"
        @submit.prevent="close_and_save"
      >
        <ButtonIconOnly
          icon="remove"
          class="modal-close-btn"
          @click-handler="close_and_save"
        >
          <VisibleText>close</VisibleText>
        </ButtonIconOnly>
      </form>
    </template>
    <div class="contactUs__wrapper">
      <p style="padding: 0 2em">
        <VisibleText
          >Would you like to meet a member of the ResearchAI team and see a demo
          of the tool?
        </VisibleText>
      </p>
      <form class="contactUs__container">
        <div class="contactUs__inputContainer">
          <h2>
            <VisibleText> Required details: </VisibleText>
          </h2>
          <label for="name"><VisibleText>Name</VisibleText>:</label>
          <br />
          <input
            class="contactUs__input"
            type="text"
            id="name"
            name="name"
            v-model="name"
          />
        </div>
        <div class="contactUs__inputContainer">
          <label for="email"><VisibleText>Email</VisibleText>:</label>
          <br />
          <input
            class="contactUs__input"
            type="text"
            id="email"
            name="email"
            v-model="email"
          />
        </div>
        <div class="contactUs__inputContainer">
          <label for="message"
            ><VisibleText
              >Describe yourself and your interest in the tool (to help us
              distinguish you from the bots)</VisibleText
            >:</label
          >
          <br />
          <textarea
            class="contactUs__textArea"
            id="message"
            v-model="message"
          ></textarea>
        </div>
        <h2 style="padding-top: 2em">
          <VisibleText> Other details: </VisibleText>
        </h2>
        <div>
          <label for="date"><VisibleText>Suggested date</VisibleText>:</label>
          <br />
          <input
            ref="suggested-date"
            type="date"
            id="form-suggested-date"
            v-model="suggestedDate"
          />
        </div>
        <div>
          <label for="date"><VisibleText>Suggested time</VisibleText>:</label>
          <br />
          <input
            ref="suggested-time"
            type="time"
            id="form-suggested-time"
            v-model="suggestedTime"
            style="margin: 1em"
          />
          <div style="font-size: 0.8em">
            <VisibleText>
              Please send in Eastern Time (Toronto, Canada)
            </VisibleText>
          </div>
        </div>
        <div class="contactUs__inputContainer">
          <label for="phone"><VisibleText>Phone</VisibleText>:</label>
          <br />
          <input
            class="contactUs__input"
            type="text"
            id="phone"
            name="phone"
            v-model="phone"
          />
        </div>

        <div class="contactUs--content-right">
          <button
            class="landing-page__button"
            @click.prevent="sendForm"
            :disabled="message === null || name === null || email === null"
          >
            <VisibleText>Send</VisibleText>
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"
import MailerService from "@/services/mailerService.js"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "BookADemo",
  components: {
    Modal,
    ButtonIconOnly
  },
  props: ["status"],
  data() {
    return {
      MAILER_SERVICE: new MailerService(this.$pigeonline),
      newStatus: false,
      firstLoad: true,
      resetListener: false,
      name: "",
      email: "",
      phone: "",
      message: "",
      suggestedDate: null,
      suggestedTime: null
    }
  },
  methods: {
    close_and_save() {
      this.isBookADemoExpanded = false
      this.$emit("close")
    },
    async sendForm() {
      try {
        await this.MAILER_SERVICE.sendContactUs({
          subject: "Book a Demo - A Message Has Been Received.",
          message: `
  From: ${this.name} <${this.email}>
  Phone: ${this.phone}
  Message: ${this.message}

  The request was initiated from following URL - ${window.location.hostname}
          `,
          to: ["hello@researchai.io"]
        })
        alert(
          "Your message has been sent successfully. A representative will be in touch with you soon.\n\nThank you."
        )
      } catch (e) {
        alert(
          "There was an error processing your request. Please contact hello@researchai.io or try again later."
        )
        throw new Error("BookADemo.vue:sendForm:: " + e)
      }
    }
  },
  computed: {
    isBookADemoExpanded: {
      get() {
        return this.newStatus
      },
      set(value) {
        this.newStatus = value
      }
    }
  },
  watch: {
    status(newVal) {
      this.newStatus = newVal
    }
  }
}
</script>
