<template>
  <div v-if="gainAccess">
    <div class="account-profile__content" v-if="profile">
      <section>
        <h1 class="account-profile__organization-title title">
          <VisibleText>Pigeon Settings</VisibleText>
        </h1>
      </section>
      <section id="platform">
        <div
          style="display: flex; flex-direction: row"
          class="account-profile__organization-title title"
        >
          <h1><VisibleText>Platform analytics</VisibleText><span> </span></h1>
          <button
            class="benchmark-manager__editor-add"
            style="height: 2rem; background-color: var(--teritary-ui-colour)"
            @click="fetchPlatformAnalytics"
          >
            <VisibleText> Fetch platform analytics</VisibleText>
          </button>
          <span
            v-if="showPlatformQueryLoading"
            class="also-found__loading-spinner spinner"
            style="
              margin-left: 2rem;
              margin-top: 0.5rem;
              width: 1rem;
              height: 1rem;
            "
          ></span>
        </div>

        <div v-if="Object.keys(platformQuery).length">
          <div>
            <VisibleText> Total datasets: </VisibleText>
            <span> {{ platformQuery.total_datasets }}</span>
          </div>
          <div>
            <VisibleText> Total projects: </VisibleText>
            <span> {{ platformQuery.total_projects }}</span>
          </div>
          <div>
            <VisibleText> Total reports: </VisibleText>
            <span> {{ platformQuery.total_reports }}</span>
          </div>
          <div>
            <VisibleText> Total rows of text uploaded: </VisibleText>
            <span> {{ platformQuery.total_rows_of_text_uploaded }}</span>
          </div>
          <div>
            <VisibleText> Total text analysis: </VisibleText>
            <span> {{ platformQuery.total_text_analyses }}</span>
          </div>
          <div>
            <VisibleText> Total themes: </VisibleText>
            <span> {{ platformQuery.total_themes }}</span>
          </div>
          <div>
            <VisibleText> Total sentiments generated: </VisibleText>
            <span> {{ platformQuery.total_sentiments_generated }}</span>
          </div>
          <div>
            <VisibleText> Total approved benchmarks: </VisibleText>
            <span> {{ platformQuery.total_approved_benchmarks }}</span>
          </div>
          <div>
            <VisibleText> Total benchmark studies: </VisibleText>
            <span> {{ platformQuery.total_benchmark_studies }}</span>
          </div>
          <div>
            <VisibleText> Total drivers analysis: </VisibleText>
            <span> {{ platformQuery.total_drivers }}</span>
          </div>

          <div>
            <VisibleText> Total outliers analysis: </VisibleText>
            <span> {{ platformQuery.total_outliers_analysis }}</span>
          </div>
          <div>
            <VisibleText> Total performance charts: </VisibleText>
            <span> {{ platformQuery.total_performance_charts }}</span>
          </div>
          <div>
            <VisibleText> Total sessions: </VisibleText>
            <span> {{ platformQuery.total_sessions }}</span>
          </div>
          <div>
            <VisibleText> Total page views: </VisibleText>
            <span> {{ platformQuery.total_route_changes }}</span>
          </div>
        </div>
      </section>
      <section id="users">
        <div
          style="display: flex; flex-direction: row; margin-top: 5rem"
          class="account-profile__organization-title title"
        >
          <h1><VisibleText>User queries</VisibleText><span> </span></h1>
          <button
            v-if="!showUserQueries"
            class="benchmark-manager__editor-add"
            style="height: 2rem; background-color: var(--teritary-ui-colour)"
            @click="showUserQueries = true"
          >
            <VisibleText> Show</VisibleText>
          </button>
          <button
            v-if="showUserQueries"
            style="height: 2rem"
            @click="showUserQueries = false"
            class="benchmark-manager__editor-add"
          >
            <VisibleText> Hide </VisibleText>
          </button>
        </div>

        <div v-if="showUserQueries">
          <h2>
            <VisibleText>User search parameters</VisibleText><span> </span>
          </h2>
          <form novalidate style="display: flex">
            <label
              for="name_or_email"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
              "
              class="form-label"
            >
              <VisibleText>Name, email, Profile ID or User ID</VisibleText>:
            </label>
            <input
              id="name_or_email"
              type="text"
              class="account-profile__form-input"
              required
              ref="name-or-email"
              style="width: 30rem"
              v-model="searchParam.search_field"
              v-on:keydown.enter.prevent="submitQuery"
            />
          </form>
          <div style="display: flex; flex-direction: row; margin-top: 1rem">
            <label
              for="license-types"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
              "
              class="form-label"
            >
              <VisibleText>License types </VisibleText>:
            </label>
            <!-- <button
              v-for="(license, license_index) in licenseColors"
              :key="'license_index' + license_index + license.color"
              id="license-types"
              @click="addToLicenses(license.license)"
              :disabled="
                searchParam.has_no_license || searchParam.is_not_verified
              "
              style="
                width: 7%;
                display: flex;
                padding: 0.3rem;
                border: 1px solid var(--outline-colour);
                font-size: 0.8rem;
                border-radius: 0.5em;
                height: 2rem;
                margin-left: 1rem;
              "
              :style="{
                'background-color': license.bgcolor,
                color: license.color
              }"
            >
              {{ capitalizeFirstLetter(license.license) }}
            </button> -->
            <div class="account-profile__organization-admin-options">
              <button
                v-for="(license, license_index) in licenseColors"
                :key="'license_index' + license_index + license.color"
                id="license-types"
                class="clickable-box"
                style="
                  font-weight: 800;
                  font-family: Core-Sans;
                  border-radius: 0.75rem;
                  height: 2rem;
                  width: 8rem;
                "
                @click="addToLicenses(license.license)"
                :disabled="
                  searchParam.has_no_license || searchParam.is_not_verified
                "
                :style="{
                  'background-color': license.bgcolor,
                  color: license.color,
                  cursor: license.cursor
                }"
              >
                <div style="display: flex; margin-right: 1rem">
                  <img
                    src="/assets/users-individual-black.png
              "
                    style="width: 2rem"
                  />
                </div>
                {{ capitalizeFirstLetter(license.license) }}
              </button>
            </div>
          </div>
          <div style="display: flex; flex-direction: row">
            <label
              for="license-types"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
                font-size: 0.8rem;
              "
              class="form-label"
            >
              <VisibleText>Selected licenses </VisibleText>:
            </label>
            <div
              style="margin-top: 0.5rem"
              v-if="
                this.searchParam.license_types.length &&
                !this.searchParam.has_no_license &&
                !searchParam.is_not_verified
              "
            >
              {{ this.searchParam.license_types.length }}
            </div>
            <label
              for="and-logic"
              style="
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
                margin-left: 1rem;
                font-size: 0.8rem;
              "
              class="form-label"
            >
              <VisibleText>And </VisibleText>:
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="and-logic"
              id="and-logic"
              v-model="searchParam.license_and_logic"
              style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
              @click="reverse_license_logic('and')"
              :disabled="this.searchParam.has_no_license"
            />
            <label
              for="or-logic"
              style="
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
                margin-left: 1rem;
                font-size: 0.8rem;
              "
              class="form-label"
            >
              <VisibleText>Or </VisibleText>:
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="or-logic"
              id="or-logic"
              v-model="searchParam.license_or_logic"
              style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
              @click="reverse_license_logic('or')"
              :disabled="this.searchParam.has_no_license"
            />
          </div>
          <div style="display: flex; flex-direction: row">
            <label
              for="empty-license"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
              "
              class="form-label"
            >
              <VisibleText>Has no license </VisibleText>:
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="dataset"
              id="empty-license"
              v-model="searchParam.has_no_license"
              style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
            />
          </div>
          <div style="display: flex; flex-direction: row">
            <label
              for="not-verified"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
              "
              class="form-label"
            >
              <VisibleText>Is not verified </VisibleText>:
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="dataset"
              id="not-verified"
              v-model="searchParam.is_not_verified"
              style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
            />
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            "
          >
            <div style="margin-left: 1rem; font-size: 0.8rem">
              <VisibleText>Sort by: </VisibleText>
              <select
                aria-label="user-sort-by"
                class="select"
                id="user-sort-by"
                v-model="searchParam.sort_type"
                style="height: 2.2rem; width: 10rem"
              >
                <option
                  v-for="option in sortOptions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </div>
            <div
              class="account-profile__organization-admin-options"
              style="
                margin-left: 1rem;
                display: flex;
                flex-direction: column;
                font-size: 0.8rem;
              "
            >
              <VisibleText>Direction: </VisibleText>

              <button
                style="
                  border: 1px solid var(--outline-colour);
                  font-family: Core-Sans;
                  border-radius: 0.75rem;
                  height: 2rem;
                  width: 3rem;
                  background-color: var(--base-lighter-ui-colour);
                "
                @click="changeSortDirection(searchParam.sort_direction)"
              >
                <SvgIconMeaningful
                  v-if="searchParam.sort_direction == 'asc'"
                  icon="ascending"
                  title="sort by ascending"
                />
                <SvgIconMeaningful
                  v-if="searchParam.sort_direction == 'desc'"
                  icon="descending"
                  title="sort by descending"
                />
              </button>
            </div>
            <button
              class="benchmark-manager__editor-add"
              @click="submitQuery"
              style="height: 2rem; margin-top: 0.8rem"
            >
              <SvgIconDecorative icon="search" />
              <VisibleText>Submit search query</VisibleText>
            </button>
          </div>
        </div>
      </section>
      <section id="user-results" v-if="showUserQueries">
        <h3
          v-if="hasRunUserSearch"
          class="account-profile__organization-title title"
        >
          <VisibleText>Results:</VisibleText>
        </h3>
        <div v-if="showQueryLoading" style="display: flex; width: 100%">
          <span
            class="also-found__loading-spinner spinner"
            style="margin: 0 auto; width: 2rem; height: 2rem"
          ></span>
        </div>
        <div
          v-for="(user, user_index) in paginatedUsers"
          :key="'user_index' + user_index"
          style="
            border: 1px solid var(--outline-colour);
            padding: 1rem;
            width: 90%;
            border-radius: 0.5em;
            background-color: white;
            margin: 0px auto;
            display: flex;
            flex-direction: column;
          "
        >
          <div style="display: flex; flex-direction: row">
            <div><VisibleText>Email</VisibleText>:</div>
            <div style="margin-left: 1rem; font-weight: 800">
              {{ user.email }}
            </div>
          </div>
          <div style="display: flex; flex-direction: row">
            <div><VisibleText>Name</VisibleText>:</div>
            <div
              v-if="user.fullname"
              style="margin-left: 1rem; font-weight: 800"
            >
              {{ user.fullname }}
            </div>
          </div>
          <div style="display: flex; flex-direction: row">
            <div><VisibleText>User ID</VisibleText>:</div>
            <div
              v-if="user.id > -1"
              style="margin-left: 1rem; font-weight: 800"
            >
              {{ user.id }}
            </div>
            <div style="margin-left: 1rem">
              <VisibleText>Profile ID</VisibleText>:
            </div>
            <div
              v-if="user.profile_id"
              style="margin-left: 1rem; font-weight: 800"
            >
              {{ user.profile_id }}
            </div>
          </div>
          <div style="display: flex; flex-direction: row">
            <div><VisibleText>Joined</VisibleText>:</div>
            <div v-if="user.date_joined" style="margin-left: 1rem">
              {{ formatDate(user.date_joined) }}
            </div>
          </div>
          <div style="display: flex; flex-direction: row">
            <div><VisibleText>Last on</VisibleText>:</div>
            <div v-if="user.last_login" style="margin-left: 1rem">
              {{ formatDate(user.last_login) }}
            </div>
          </div>
          <div style="display: flex; flex-direction: row">
            <div v-if="user.is_email_verified">
              <VisibleText>Email verified</VisibleText>
            </div>
            <div
              v-if="!user.is_email_verified"
              style="display: flex; flex-direction: row"
            >
              <VisibleText
                >Email
                <span style="text-decoration: underline">not</span>
                verified</VisibleText
              >
              <ButtonIconOnly
                @click-handler="verifyThisUser(user, user_index)"
                icon="check"
                class="inline-edit-text__action-btn"
                :smaller="true"
                style="color: black; display: flex; margin-left: 0.8rem"
              >
                <VisibleText>Verify user email</VisibleText>
              </ButtonIconOnly>
            </div>
          </div>
          <div>
            <VisibleText> Licenses: </VisibleText>
          </div>
          <div style="display: flex; flex-direction: row">
            <div
              v-if="licenses.length"
              class="account-profile__organization-admin-options"
            >
              <LicenseCardSmall
                v-for="(license, index) in user.licenses"
                :key="'license-button' + index"
                :license="license"
                @changeSelectedLicense="
                  changeSelectedLicense(license, user_index)
                "
              >
              </LicenseCardSmall>
            </div>

            <ButtonIconOnly
              @click-handler="setShowAddUserLicense(user_index)"
              v-if="
                !showAddUserLicense.includes(user_index) &&
                user.is_email_verified
              "
              icon="add"
              class="inline-edit-text__action-btn"
              style="color: black; display: flex"
              :smaller="true"
            >
              <VisibleText>Add license</VisibleText>
            </ButtonIconOnly>
            <ButtonIconOnly
              v-if="
                showAddUserLicense.includes(user_index) &&
                user.is_email_verified
              "
              @click-handler="setShowAddUserLicense(user_index)"
              icon="remove"
              class="inline-edit-text__action-btn"
              style="color: black; display: flex; margin-top: 1rem"
              :smaller="true"
            >
              <VisibleText>Cancel adding license</VisibleText>
            </ButtonIconOnly>
          </div>
          <div>
            <button
              class="benchmark-manager__editor-add"
              @click="fetchUserAnalytics(user.profile_id, user_index)"
              style="
                height: 2rem;
                margin-top: 0.8rem;
                font-size: 0.8rem;
                background-color: var(--teritary-ui-colour);
              "
            >
              <SvgIconDecorative icon="information" />
              <VisibleText>Fetch user analytics</VisibleText>
            </button>
            <span
              v-if="isUserAnalyticsLoading(user_index)"
              class="also-found__loading-spinner spinner"
              style="
                margin: 0 auto;
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
              "
            ></span>
          </div>
          <div v-if="user.additional_analytics">
            <div>
              <VisibleText>
                Total datasets:
                <span>{{ user.additional_analytics.total_datasets }}</span>
              </VisibleText>
            </div>
            <div>
              <VisibleText>
                Total projects:
                <span>{{ user.additional_analytics.total_projects }}</span>
              </VisibleText>
            </div>
            <div>
              <VisibleText>
                Total drivers analysis:
                <span>{{ user.additional_analytics.total_drivers }}</span>
              </VisibleText>
            </div>
            <div>
              <VisibleText>
                Total benchmark studies:
                <span>{{
                  user.additional_analytics.total_benchmark_studies
                }}</span>
              </VisibleText>
            </div>
            <div>
              <VisibleText>
                Total text analyses:
                <span>{{ user.additional_analytics.total_text_analyses }}</span>
              </VisibleText>
            </div>
            <div>
              <VisibleText>
                Total outliers analysis:
                <span>{{
                  user.additional_analytics.total_outliers_analysis
                }}</span>
              </VisibleText>
            </div>
            <div>
              <VisibleText>
                Total performance analysis:
                <span>{{
                  user.additional_analytics.total_performance_charts
                }}</span>
              </VisibleText>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="display: flex">
                <VisibleText
                  >Unique visits (clearing cookies resets this)</VisibleText
                >
              </div>
              <button
                @click="
                  expandThisDeviceHistory(user.show_device_history, user_index)
                "
                style="background-color: transparent; border: none"
              >
                <SvgIconMeaningful
                  :icon="
                    !user.show_device_history ? 'chevronDown' : 'chevronUp'
                  "
                  title="hide visits"
                />
              </button>
            </div>

            <div
              v-if="
                user.additional_analytics.device_visits.length &&
                user.show_device_history
              "
            >
              <div
                v-for="(session, index) in user.additional_analytics
                  .device_visits"
                :key="index"
              >
                <UserSession :userSession="session"></UserSession>
              </div>
            </div>
          </div>
          <div
            v-if="showAddUserLicense.includes(user_index) && isPigeon"
            style="margin-bottom: 1rem"
          >
            <h3>
              <VisibleText> Choose a license to add </VisibleText>
            </h3>
            <div style="display: flex; flex-direction: row">
              <select
                aria-label="license"
                class="select"
                id="select-license"
                v-model="addingUserLicense"
                style="height: 2.2rem; width: 10rem"
              >
                <option
                  v-for="license in filteredLicenses(user)"
                  :key="license"
                  :value="license"
                >
                  {{ license }}
                </option>
              </select>
              <button
                v-if="addingUserLicense"
                @click="addUserLicense(addingUserLicense, user, user_index)"
                class="inline-edit-text__action-btn"
                style="width: 6rem; color: black; margin-left: 1rem"
              >
                <VisibleText>Add license</VisibleText>
              </button>
            </div>
          </div>

          <div v-if="user.selectedLicense && user.selectedLicense.length">
            <LicenseCardLarge
              v-for="(license, user_license_index) in user.selectedLicense"
              :key="
                'license-card' + user + user_license_index + license.license_id
              "
              class="form-label"
              style="
                border: 1px solid var(--outline-colour);
                background-color: var(--base-lighter-ui-colour);
                width: 24rem;
                height: 12rem;
                border-radius: 1rem;
                margin-left: 2rem;
                margin-top: 1rem;
              "
              :license="license"
              :userIndex="user_index"
              :user="user"
              @removeUserLicense="removeUserLicense"
            >
            </LicenseCardLarge>
          </div>
        </div>
        <div class="topOutliers__footer">
          <button
            v-if="canLoadMoreUsers"
            class="btn-nobg--secondary"
            type="button"
            @click="onClickLoadMoreUsers"
          >
            <VisibleText>load more users</VisibleText>
          </button>
        </div>
      </section>
      <section id="teams">
        <div
          style="display: flex; flex-direction: row; margin-top: 5rem"
          class="account-profile__organization-title title"
        >
          <h1><VisibleText>Team queries</VisibleText><span> </span></h1>
          <button
            v-if="!showTeamQueries"
            class="benchmark-manager__editor-add"
            style="height: 2rem; background-color: var(--teritary-ui-colour)"
            @click="showTeamQueries = true"
          >
            <VisibleText> Show </VisibleText>
          </button>
          <button
            v-if="showTeamQueries"
            style="height: 2rem"
            class="benchmark-manager__editor-add"
            @click="showTeamQueries = false"
          >
            <VisibleText> Hide </VisibleText>
          </button>
        </div>

        <div v-if="showTeamQueries">
          <h2>
            <VisibleText>Team search parameters</VisibleText><span> </span>
          </h2>
          <form novalidate style="display: flex">
            <label
              for="team_name"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
              "
              class="form-label"
            >
              <VisibleText>Team name or team id</VisibleText>:
            </label>
            <input
              id="team_name"
              type="text"
              class="account-profile__form-input"
              required
              ref="team-name"
              style="width: 30rem"
              v-model="teamSearchParam.name_search_field"
              v-on:keydown.enter.prevent="submitTeamQuery"
            />
          </form>
          <form novalidate style="display: flex">
            <label
              for="team_has_members"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
              "
              class="form-label"
            >
              <VisibleText>Team has member (email/id)</VisibleText>:
            </label>
            <input
              id="team_has_members"
              type="text"
              class="account-profile__form-input"
              required
              ref="team-has-members"
              style="width: 30rem"
              v-model="teamSearchParam.member_search_field"
              v-on:keydown.enter.prevent="submitTeamQuery"
            />
          </form>
          <div style="display: flex; flex-direction: row; margin-top: 1rem">
            <label
              for="license-types"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
              "
              class="form-label"
            >
              <VisibleText>License types </VisibleText>:
            </label>
            <div class="account-profile__organization-admin-options">
              <button
                style="
                  font-weight: 800;
                  font-family: Core-Sans;
                  border-radius: 0.75rem;
                  height: 2rem;
                  width: 8rem;
                "
                v-for="(license, license_index) in teamLicenseColors"
                :key="'license_index' + license_index + license.color"
                id="license-types"
                @click="addToTeamLicenses(license.license)"
                :disabled="teamSearchParam.has_no_license"
                :style="{
                  'background-color': license.bgcolor,
                  color: license.color,
                  cursor: license.cursor
                }"
                class="clickable-box"
              >
                <img
                  src="/assets/users-individual-black.png
              "
                  style="width: 2rem"
                />
                {{ capitalizeFirstLetter(license.license) }}
              </button>
            </div>
          </div>
          <div style="display: flex; flex-direction: row">
            <label
              for="license-types"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
                font-size: 0.8rem;
              "
              class="form-label"
            >
              <VisibleText>Selected licenses </VisibleText>:
            </label>
            <div
              style="margin-top: 0.5rem"
              v-if="
                this.teamSearchParam.license_types.length &&
                !this.teamSearchParam.has_no_license
              "
            >
              {{ this.teamSearchParam.license_types.length }}
            </div>
            <label
              for="team-and-logic"
              style="
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
                margin-left: 1rem;
                font-size: 0.8rem;
              "
              class="form-label"
            >
              <VisibleText>And </VisibleText>:
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="team-and-logic"
              id="team-and-logic"
              v-model="teamSearchParam.license_and_logic"
              style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
              @click="reverse_team_license_logic('and')"
              :disabled="this.teamSearchParam.has_no_license"
            />
            <label
              for="team-or-logic"
              style="
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
                margin-left: 1rem;
                font-size: 0.8rem;
              "
              class="form-label"
            >
              <VisibleText>Or </VisibleText>:
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="team-or-logic"
              id="team-or-logic"
              v-model="teamSearchParam.license_or_logic"
              style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
              @click="reverse_team_license_logic('or')"
              :disabled="this.teamSearchParam.has_no_license"
            />
          </div>
          <div style="display: flex; flex-direction: row">
            <label
              for="empty-team-license"
              style="
                width: 20rem;
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
              "
              class="form-label"
            >
              <VisibleText>Has no license </VisibleText>:
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="empty-team-license"
              id="empty-team-license"
              v-model="teamSearchParam.has_no_license"
              style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
            />
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            "
          >
            <div style="margin-left: 1rem; font-size: 0.8rem">
              <VisibleText>Sort by: </VisibleText>
              <select
                aria-label="sort-by"
                class="select"
                id="sort-by"
                v-model="teamSearchParam.sort_type"
                style="height: 2.2rem; width: 10rem"
              >
                <option
                  v-for="option in sortOptions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </div>
            <div
              class="account-profile__organization-admin-options"
              style="
                margin-left: 1rem;
                display: flex;
                flex-direction: column;
                font-size: 0.8rem;
              "
            >
              <VisibleText>Direction: </VisibleText>

              <button
                style="
                  border: 1px solid var(--outline-colour);
                  font-family: Core-Sans;
                  border-radius: 0.75rem;
                  height: 2rem;
                  width: 3rem;
                  background-color: var(--base-lighter-ui-colour);
                "
                @click="changeTeamSortDirection(teamSearchParam.sort_direction)"
              >
                <SvgIconMeaningful
                  v-if="teamSearchParam.sort_direction == 'asc'"
                  icon="ascending"
                  title="sort by ascending"
                />
                <SvgIconMeaningful
                  v-if="teamSearchParam.sort_direction == 'desc'"
                  icon="descending"
                  title="sort by descending"
                />
              </button>
            </div>
            <button
              class="benchmark-manager__editor-add"
              @click="submitTeamQuery"
              style="height: 2rem; margin-top: 0.8rem"
            >
              <SvgIconDecorative icon="search" />
              <VisibleText>Submit search query</VisibleText>
            </button>
          </div>
        </div>
      </section>
      <section id="team-results" v-if="showTeamQueries">
        <h3
          v-if="hasRunTeamSearch"
          class="account-profile__organization-title title"
        >
          <VisibleText>Results:</VisibleText>
        </h3>
        <div v-if="showTeamQueryLoading" style="display: flex; width: 100%">
          <span
            class="also-found__loading-spinner spinner"
            style="margin: 0 auto; width: 2rem; height: 2rem"
          ></span>
        </div>
        <div style="display: flex; flex-direction: row; width: 100%">
          <div style="display: flex; flex-direction: column; width: 60%">
            <div
              v-for="(team, team_index) in localTeams"
              :key="'team_index' + team_index + team.last_modified.$date"
              style="
                border: 1px solid var(--outline-colour);
                padding: 1rem;

                border-radius: 0.5em;
                background-color: white;
                margin: 0px auto;
                display: flex;
                flex-direction: row;
              "
            >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <div><VisibleText>Team</VisibleText>:</div>
                  <div style="margin-left: 1rem; font-weight: 800">
                    {{ team.team_name }}
                  </div>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div><VisibleText>Team ID</VisibleText>:</div>
                  <div
                    v-if="team._id"
                    style="margin-left: 1rem; font-weight: 800"
                  >
                    {{ team._id.$oid }}
                  </div>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div><VisibleText>Team members</VisibleText>:</div>
                  <div style="margin-left: 1rem">
                    {{ team.team_members.length + team.admins.length }}
                  </div>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div><VisibleText>Created</VisibleText>:</div>
                  <div style="margin-left: 1rem">
                    {{ formatDate(team.created) }}
                  </div>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div><VisibleText>Last modified</VisibleText>:</div>
                  <div style="margin-left: 1rem">
                    {{ formatDate(team.last_modified) }}
                  </div>
                </div>
              </div>
              <!-- selectedTeam is handled by the userCardMixin -->
              <div style="display: flex; margin-left: auto">
                <div v-if="selectedTeam[0] == team">
                  <button
                    @click="changeSelectedTeam(team)"
                    class="benchmark-manager__editor-add"
                    style="height: 2rem"
                  >
                    <VisibleText> Hide team details </VisibleText>
                  </button>
                </div>
                <button
                  v-if="selectedTeam[0] != team"
                  @click="changeSelectedTeam(team)"
                  class="benchmark-manager__editor-add"
                  style="
                    height: 2rem;
                    background-color: var(--teritary-ui-colour);
                  "
                >
                  <VisibleText> Show team details </VisibleText>
                </button>
              </div>
            </div>
          </div>
          <div v-if="selectedTeam" style="display: flex; min-width: 60%">
            <TeamsCardLarge
              v-for="(team, team_index) in selectedTeam"
              :key="'team-card' + team_index + team.last_modified.$date"
              class="form-label"
              style="
                border: 1px solid var(--outline-colour);
                padding: 1rem;
                width: 80%;
                border-radius: 0.5em;
                background-color: var(--base-lighter-ui-colour);
                margin-left: 2rem;
                margin-top: 1rem;
              "
              :team="team"
              :teamIndex="getTeamIndex(team)"
              :email="email"
              @resetTheseTeams="resetTheseTeams"
              @deleteThisTeam="deleteThisTeam"
            >
            </TeamsCardLarge>
          </div>
        </div>
      </section>
      <section id="audits-and-fixes">
        <div
          style="display: flex; flex-direction: row; margin-top: 5rem"
          class="account-profile__organization-title title"
        >
          <h1><VisibleText>Audits and fixes</VisibleText><span> </span></h1>
          <button
            v-if="!showAuditQueries"
            class="benchmark-manager__editor-add"
            style="height: 2rem; background-color: var(--teritary-ui-colour)"
            @click="showAuditQueries = true"
          >
            <VisibleText> Show </VisibleText>
          </button>
        </div>
      </section>

      <section id="audits-and-fixes-results" v-if="showAuditQueries">
        <h2>
          <VisibleText>Remove zombie files</VisibleText>
        </h2>
        <p>
          <VisibleText
            >Zombie files are database files that were created but not properly
            handled in terms of deletion after use.</VisibleText
          >
        </p>
        <p>
          <VisibleText
            >For example, prior to 1.9.5 FE (1.9.2 BE) one could start a dataset
            upload, but leave before confirming information like the correct
            excel sheet to use. If this happens, there is no db.project dataset
            object created, meaning the view datasets cannot call it up to allow
            its db.datasets and db.survey_files to be deleted. These then become
            zombie files.</VisibleText
          >
        </p>
        <div
          style="
            display: flex;
            flex-direction: row;
            height: 2rem;
            margin-bottom: 1rem;
          "
        >
          <button
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('survey_files')"
          >
            Find zombie survey files
          </button>
          <div v-if="audit.zombie_surveys_loading" style="display: flex">
            <span
              class="also-found__loading-spinner spinner"
              style="
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
              "
            ></span>
          </div>
          <div
            v-if="audit.zombie_surveys_found > -1"
            style="margin-top: 0.5rem; margin-left: 1rem"
          >
            {{ audit.zombie_surveys_found }} zombie survey files found
          </div>
          <button
            v-if="audit.zombie_surveys_found > 0 || audit.force_delete"
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('survey_files', true)"
            style="
              background-color: var(--error-bg-colour);
              color: var(--error-text-colour);
            "
          >
            Delete (Warning, cannot be reversed)
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            height: 2rem;
            margin-bottom: 1rem;
          "
        >
          <button
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('datasets')"
          >
            Find zombie datasets
          </button>
          <div v-if="audit.zombie_datasets_loading" style="display: flex">
            <span
              class="also-found__loading-spinner spinner"
              style="
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
              "
            ></span>
          </div>
          <div
            v-if="audit.zombie_datasets_found > -1"
            style="margin-top: 0.5rem; margin-left: 1rem"
          >
            {{ audit.zombie_datasets_found }} zombie datasets found
          </div>
          <button
            v-if="audit.zombie_datasets_found > 0 || audit.force_delete"
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('datasets', true)"
            style="
              background-color: var(--error-bg-colour);
              color: var(--error-text-colour);
            "
          >
            Delete (Warning, cannot be reversed)
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            height: 2rem;
            margin-bottom: 1rem;
          "
        >
          <button
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('client_questions')"
          >
            Find zombie client_questions
          </button>
          <div
            v-if="audit.zombie_client_questions_loading"
            style="display: flex"
          >
            <span
              class="also-found__loading-spinner spinner"
              style="
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
              "
            ></span>
          </div>
          <div
            v-if="audit.zombie_client_questions_found > -1"
            style="margin-top: 0.5rem; margin-left: 1rem"
          >
            {{ audit.zombie_client_questions_found }} zombie client_questions
            found
          </div>
          <button
            v-if="audit.zombie_client_questions_found > 0 || audit.force_delete"
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('client_questions', true)"
            style="
              background-color: var(--error-bg-colour);
              color: var(--error-text-colour);
            "
          >
            Delete (Warning, cannot be reversed)
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            height: 2rem;
            margin-bottom: 1rem;
          "
        >
          <button
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('benchmarks')"
          >
            Find zombie benchmarks
          </button>
          <div v-if="audit.zombie_benchmarks_loading" style="display: flex">
            <span
              class="also-found__loading-spinner spinner"
              style="
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
              "
            ></span>
          </div>
          <div
            v-if="audit.zombie_benchmarks_found > -1"
            style="margin-top: 0.5rem; margin-left: 1rem"
          >
            {{ audit.zombie_benchmarks_found }} zombie benchmarks found
          </div>
          <button
            v-if="audit.zombie_benchmarks_found > 0 || audit.force_delete"
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('benchmarks', true)"
            style="
              background-color: var(--error-bg-colour);
              color: var(--error-text-colour);
            "
          >
            Delete (Warning, cannot be reversed)
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            height: 2rem;
            margin-bottom: 1rem;
          "
        >
          <button
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('text_responses')"
          >
            Find zombie text_responses
          </button>
          <div v-if="audit.zombie_text_responses_loading" style="display: flex">
            <span
              class="also-found__loading-spinner spinner"
              style="
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
              "
            ></span>
          </div>
          <div
            v-if="audit.zombie_text_responses_found > -1"
            style="margin-top: 0.5rem; margin-left: 1rem"
          >
            {{ audit.zombie_text_responses_found }} zombie text_responses found
          </div>
          <button
            v-if="audit.zombie_text_responses_found > 0 || audit.force_delete"
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('text_responses', true)"
            style="
              background-color: var(--error-bg-colour);
              color: var(--error-text-colour);
            "
          >
            Delete (Warning, cannot be reversed)
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            height: 2rem;
            margin-bottom: 1rem;
          "
        >
          <button
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('text_lemmas')"
          >
            Find zombie text_lemmas
          </button>
          <div v-if="audit.zombie_text_lemmas_loading" style="display: flex">
            <span
              class="also-found__loading-spinner spinner"
              style="
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
              "
            ></span>
          </div>
          <div
            v-if="audit.zombie_text_lemmas_found > -1"
            style="margin-top: 0.5rem; margin-left: 1rem"
          >
            {{ audit.zombie_text_lemmas_found }} zombie text_lemmas found
          </div>
          <button
            v-if="audit.zombie_text_lemmas_found > 0 || audit.force_delete"
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('text_lemmas', true)"
            style="
              background-color: var(--error-bg-colour);
              color: var(--error-text-colour);
            "
          >
            Delete (Warning, cannot be reversed)
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            height: 2rem;
            margin-bottom: 1rem;
          "
        >
          <button
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('sentence_embeddings')"
          >
            Find zombie sentence_embeddings
          </button>
          <div
            v-if="audit.zombie_sentence_embeddings_loading"
            style="display: flex"
          >
            <span
              class="also-found__loading-spinner spinner"
              style="
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
              "
            ></span>
          </div>
          <div
            v-if="audit.zombie_sentence_embeddings_found > -1"
            style="margin-top: 0.5rem; margin-left: 1rem"
          >
            {{ audit.zombie_sentence_embeddings_found }} zombie
            sentence_embeddings found
          </div>
          <button
            v-if="
              audit.zombie_sentence_embeddings_found > 0 || audit.force_delete
            "
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('sentence_embeddings', true)"
            style="
              background-color: var(--error-bg-colour);
              color: var(--error-text-colour);
            "
          >
            Delete (Warning, cannot be reversed)
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            height: 2rem;
            margin-bottom: 1rem;
          "
        >
          <button
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('themes')"
          >
            Find zombie themes
          </button>
          <div v-if="audit.zombie_themes_loading" style="display: flex">
            <span
              class="also-found__loading-spinner spinner"
              style="
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
              "
            ></span>
          </div>
          <div
            v-if="audit.zombie_themes_found > -1"
            style="margin-top: 0.5rem; margin-left: 1rem"
          >
            {{ audit.zombie_themes_found }} zombie themes found
          </div>
          <button
            v-if="audit.zombie_themes_found > 0 || audit.force_delete"
            class="benchmark-manager__editor-add"
            @click="findZombieDatasets('themes', true)"
            style="
              background-color: var(--error-bg-colour);
              color: var(--error-text-colour);
            "
          >
            Delete (Warning, cannot be reversed)
          </button>
        </div>
        <div>
          <input
            type="checkbox"
            class="datasets__table-checkbox"
            name="and-logic"
            id="and-logic-force-delete"
            v-model="audit.force_delete"
            style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
          />
          <VisibleText
            >Force open the delete buttons. Useful for when a zombie checker
            times out, as deletions happen individually.</VisibleText
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import UserMixin from "@/utils/mixins/userMixin"
import ConfirmMixin from "/src/utils/mixins/confirmMixin.js"
import AdminService from "@/services/adminService.js"
import DatasetsService from "@/services/datasetsService.js"

import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
import LicenseCardSmall from "@/components/UI/UserCards/LicenseCardSmall.vue"
import LicenseCardLarge from "@/components/UI/UserCards/LicenseCardLarge.vue"
import UserCardsMixin from "@/components/UI/UserCards/userCardsMixin"
import TeamsCardLarge from "@/components/UI/UserCards/TeamsCardLarge.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import UserSession from "@/components/UserAnalytics/UserSession.vue"

export default {
  name: "pigeonSettings",
  mixins: [UserMixin, ConfirmMixin, UserCardsMixin],
  components: {
    LicenseCardSmall,
    LicenseCardLarge,
    TeamsCardLarge,
    ButtonIconOnly,
    SvgIconDecorative,
    SvgIconMeaningful,
    UserSession
  },
  data() {
    return {
      ADMIN_SERVICE: new AdminService(this.$pigeonline),
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      hasAccess: false,
      showPlatformQueryLoading: false,
      showQueryLoading: false,
      showTeamQueryLoading: false,
      loadingUserAnalytics: [],
      searchParam: {
        search_field: "",
        license_types: [],
        license_and_logic: true,
        license_or_logic: false,
        has_no_license: false,
        is_not_verified: false,
        sort_type: "created",
        sort_direction: "desc"
      },
      userPagination: {
        endIndx: 25,
        limit: 25, //limit of how many are shown in a page
        startIndx: 0
      },
      teamSearchParam: {
        name_search_field: "",
        member_search_field: "",
        license_types: [],
        license_and_logic: true,
        license_or_logic: false,
        has_no_license: false,
        sort_type: "created",
        sort_direction: "desc"
      },
      hasRunTeamSearch: false,
      hasRunUserSearch: false,
      sortOptions: ["created", "last_modified"],
      queriedUsers: [],
      platformQuery: {},
      showUserQueries: false,
      showTeamQueries: false,
      showAuditQueries: false,
      showAddUserLicense: [], // opens the window allowing you to add new licenses to a user
      addingUserLicense: "", // the license type to apply to a user
      audit: {
        zombie_surveys_found: -1,
        zombie_surveys_loading: false,
        zombie_datasets_found: -1,
        zombie_datasets_loading: false,
        zombie_client_questions_found: -1,
        zombie_client_questions_loading: false,
        zombie_benchmarks_found: -1,
        zombie_benchmarks_loading: false,
        zombie_text_responses_found: -1,
        zombie_text_responses_loading: false,
        zombie_text_lemmas_found: -1,
        zombie_text_lemmas_loading: false,
        zombie_sentence_embeddings_found: -1,
        zombie_sentence_embeddings_loading: false,
        zombie_themes_found: -1,
        zombie_themes_loading: false,
        force_delete: false
      }
    }
  },
  computed: {
    paginatedUsers: function () {
      return this.queriedUsers.slice(
        this.userPagination.startIndx,
        this.userPagination.endIndx
      )
    },
    canLoadMoreUsers() {
      let status = false
      if (this.queriedUsers.length > this.paginatedUsers.length) {
        return true
      }

      return status
    },
    gainAccess() {
      let access = false
      if (
        this.parsedLicenses &&
        this.parsedLicenses.length &&
        this.parsedLicenses.includes("pigeon")
      ) {
        access = true
      }
      return access
    },
    revokeAccess() {
      let revoke_access = false
      if (
        this.parsedLicenses &&
        this.parsedLicenses.length &&
        !this.parsedLicenses.includes("pigeon")
      ) {
        revoke_access = true
      }
      return revoke_access
    },
    licenseColors() {
      let theseColors = []
      for (let i = 0; i < this.licenseTypes.length; i++) {
        let newColor = {
          license: this.licenseTypes[i],
          color: "#000",
          bgcolor: "var(--base-lighter-ui-colour)",
          cursor: "pointer"
        }

        if (this.searchParam.license_types.includes(this.licenseTypes[i])) {
          newColor = {
            license: this.licenseTypes[i],
            color: "#fff",
            bgcolor: "var(--primary-brand-ui-colour)"
          }
        }
        if (
          this.searchParam.has_no_license ||
          this.searchParam.is_not_verified
        ) {
          newColor = {
            license: this.licenseTypes[i],
            color: "#aaa",
            bgcolor: "var(--outline-colour)",
            cursor: "not-allowed"
          }
        }
        theseColors.push(newColor)
      }
      return theseColors
    },
    teamLicenseColors() {
      let theseColors = []
      for (let i = 0; i < this.licenseTypes.length; i++) {
        let newColor = {
          license: this.licenseTypes[i],
          color: "#000",
          bgcolor: "var(--base-lighter-ui-colour)",
          cursor: "pointer"
        }

        if (this.teamSearchParam.license_types.includes(this.licenseTypes[i])) {
          newColor = {
            license: this.licenseTypes[i],
            color: "#fff",
            bgcolor: "var(--primary-brand-ui-colour)"
          }
        }
        if (this.teamSearchParam.has_no_license) {
          newColor = {
            license: this.licenseTypes[i],
            color: "#aaa",
            bgcolor: "var(--outline-colour)",
            cursor: "not-allowed"
          }
        }
        theseColors.push(newColor)
      }
      return theseColors
    }
  },
  async mounted() {},
  methods: {
    changeSortDirection(direction) {
      if (direction == "asc") {
        this.searchParam.sort_direction = "desc"
      } else {
        this.searchParam.sort_direction = "asc"
      }
    },
    async fetchPlatformAnalytics() {
      this.showPlatformQueryLoading = true
      this.platformQuery = await this.ADMIN_SERVICE.platformQuery()
      this.showPlatformQueryLoading = false
    },
    isUserAnalyticsLoading(user_index) {
      if (user_index > -1) {
        let filteredLoadingUserAnalytics = this.loadingUserAnalytics.filter(
          (user) => user == user_index
        )
        if (
          filteredLoadingUserAnalytics &&
          filteredLoadingUserAnalytics.length
        ) {
          return true
        }
      }
      return false
    },
    async submitQuery() {
      this.queriedUsers = []
      this.showQueryLoading = true
      this.resetUserPagination()
      this.hasRunUserSearch = true
      let cleanedParam = {}

      if (this.searchParam.sort_type == "created") {
        cleanedParam["sort_created"] = this.searchParam.sort_direction
      }

      if (this.searchParam.sort_type == "last_modified") {
        cleanedParam["sort_last_modified"] = this.searchParam.sort_direction
      }

      if (this.searchParam.search_field) {
        cleanedParam["name_or_email"] = this.searchParam.search_field
      }
      if (
        this.searchParam.license_types &&
        this.searchParam.license_types.length &&
        !this.searchParam.has_no_license &&
        !this.searchParam.is_not_verified
      ) {
        cleanedParam["license_types"] = this.searchParam.license_types
        let default_logic = "and"
        if (this.searchParam.license_or_logic) {
          default_logic = "or"
        }
        cleanedParam["license_logic"] = default_logic
      }
      if (this.searchParam.has_no_license) {
        cleanedParam["has_no_license"] = this.searchParam.has_no_license
      }
      if (this.searchParam.is_not_verified) {
        cleanedParam["is_not_verified"] = this.searchParam.is_not_verified
      }

      this.queriedUsers = await this.ADMIN_SERVICE.userQuery(cleanedParam)

      // This is used to show the larger license
      for (let i = 0; i < this.queriedUsers.length; i++) {
        this.queriedUsers[i].selectedLicense = []
      }
      this.showQueryLoading = false
    },
    onClickLoadMoreUsers() {
      this.userPagination.endIndx =
        this.userPagination.endIndx + this.userPagination.limit
    },
    resetUserPagination() {
      this.userPagination["endIndx"] = 25
      this.userPagination["limit"] = 25
      this.userPagination["startIndx"] = 0
    },
    changeTeamSortDirection(direction) {
      if (direction == "asc") {
        this.teamSearchParam.sort_direction = "desc"
      } else {
        this.teamSearchParam.sort_direction = "asc"
      }
    },
    async fetchUserAnalytics(profile_id, user_index) {
      this.loadingUserAnalytics = this.loadingUserAnalytics.filter(
        (user) => user != user_index
      )
      this.loadingUserAnalytics.push(user_index)
      let cleanedParam = {
        profile_id: profile_id
      }
      let user_analytics =
        await this.ADMIN_SERVICE.getUserAnalytics(cleanedParam)
      if (user_analytics) {
        const clonedUsers = this.deepCloneObj(this.queriedUsers)
        let updated_user = clonedUsers[user_index]
        updated_user["additional_analytics"] = user_analytics
        updated_user["show_device_history"] = true
        if (updated_user) {
          clonedUsers[user_index] = updated_user
          this.queriedUsers = []
          this.queriedUsers = clonedUsers
        }
      }
      this.loadingUserAnalytics = this.loadingUserAnalytics.filter(
        (user) => user != user_index
      )
    },
    expandThisDeviceHistory(status, user_index) {
      const clonedUsers = this.deepCloneObj(this.queriedUsers)
      let updated_user = clonedUsers[user_index]
      updated_user["show_device_history"] = !status
      if (updated_user) {
        clonedUsers[user_index] = updated_user
        this.queriedUsers = []
        this.queriedUsers = clonedUsers
      }
    },
    async submitTeamQuery() {
      this.showTeamQueryLoading = true
      this.localTeams = []
      this.selectedTeam = []
      this.hasRunTeamSearch = true
      let cleanedParam = {
        include_details: true
      }

      if (this.teamSearchParam.sort_type == "created") {
        cleanedParam["sort_created"] = this.teamSearchParam.sort_direction
      }

      if (this.teamSearchParam.sort_type == "last_modified") {
        cleanedParam["sort_last_modified"] = this.teamSearchParam.sort_direction
      }

      if (this.teamSearchParam.name_search_field) {
        cleanedParam["name_search_field"] =
          this.teamSearchParam.name_search_field
      }
      if (this.teamSearchParam.member_search_field) {
        cleanedParam["member_search_field"] =
          this.teamSearchParam.member_search_field
      }
      if (
        this.teamSearchParam.license_types &&
        this.teamSearchParam.license_types.length &&
        !this.teamSearchParam.has_no_license
      ) {
        cleanedParam["license_types"] = this.teamSearchParam.license_types
        let default_logic = "and"
        if (this.teamSearchParam.license_or_logic) {
          default_logic = "or"
        }
        cleanedParam["license_logic"] = default_logic
      }
      if (this.teamSearchParam.has_no_license) {
        cleanedParam["has_no_license"] = this.teamSearchParam.has_no_license
      }
      this.localTeams = await this.ADMIN_SERVICE.getAllTeams(cleanedParam)
      this.showTeamQueryLoading = false
    },
    addToLicenses(license) {
      if (license) {
        if (!this.searchParam.license_types.includes(license)) {
          this.searchParam.license_types.push(license)
        } else {
          this.searchParam.license_types =
            this.searchParam.license_types.filter(
              (this_license) => this_license != license
            )
        }
      }
    },
    addToTeamLicenses(license) {
      if (license) {
        if (!this.teamSearchParam.license_types.includes(license)) {
          this.teamSearchParam.license_types.push(license)
        } else {
          this.teamSearchParam.license_types =
            this.teamSearchParam.license_types.filter(
              (this_license) => this_license != license
            )
        }
      }
    },
    setShowAddUserLicense(user_index) {
      if (this.showAddUserLicense.includes(user_index)) {
        this.showAddUserLicense = []
      } else {
        this.showAddUserLicense.push(user_index)
      }
    },
    async addUserLicense(license, user, user_index) {
      await this.ADMIN_SERVICE.addLicense({
        name: license,
        profile_id: user.profile_id
      })
      this.resetThisUser(user.profile_id, user_index)
      this.setShowAddUserLicense(user_index)
    },
    async removeUserLicense(license, user, user_index) {
      await this.changeSelectedLicense(license, user_index)
      await this.ADMIN_SERVICE.deleteLicense({
        license_id: license.license_id
      })
      this.resetThisUser(user.profile_id, user_index)
    },

    async resetThisUser(profile_id, user_index) {
      const clonedUsers = this.deepCloneObj(this.queriedUsers)
      if (clonedUsers && clonedUsers.length) {
        let updated_users = await this.ADMIN_SERVICE.userQuery({
          profile_id: profile_id
        })

        if (updated_users) {
          clonedUsers[user_index] = updated_users[0]
        }
        this.queriedUsers = []
        this.queriedUsers = clonedUsers
      }
    },
    formatDate(timestamp) {
      return new Date(timestamp.$date).toLocaleString("en-GB")
    },
    filteredLicenses(user) {
      let userLicenses = []
      let filteredLicenses = this.licenseTypes
      if (user.licenses && user.licenses.length) {
        userLicenses = user.licenses
          .filter((license) => license.profile_id)
          .map((license) => license.name)
      }

      filteredLicenses = this.licenseTypes.filter(
        (license) => !userLicenses.includes(license)
      )
      return filteredLicenses
    },
    async verifyThisUser(user, user_index) {
      await this.ADMIN_SERVICE.verifyUserEmail({
        profile_id: user.profile_id,
        is_verified: true
      })
      this.resetThisUser(user.profile_id, user_index)
    },
    reverse_license_logic(logic) {
      if (logic == "and") {
        this.searchParam.license_or_logic = this.searchParam.license_and_logic
      }
      if (logic == "or") {
        this.searchParam.license_and_logic = this.searchParam.license_or_logic
      }
    },
    reverse_team_license_logic(logic) {
      if (logic == "and") {
        this.teamSearchParam.license_or_logic =
          this.teamSearchParam.license_and_logic
      }
      if (logic == "or") {
        this.teamSearchParam.license_and_logic =
          this.teamSearchParam.license_or_logic
      }
    },
    async findZombieDatasets(method, deleteZombie) {
      let params = {
        delete_files: false
      }
      if (deleteZombie) {
        params["delete_files"] = true
      }
      if (method == "survey_files") {
        params["include_surveys"] = true
        this.audit.zombie_surveys_loading = true
        let thisAudit = await this.DATASETS_SERVICE.findZombieDatasets(params)
        if (deleteZombie) {
          this.audit.zombie_surveys_found = -1
        } else {
          this.audit.zombie_surveys_found =
            thisAudit["all_zombie_surveys_no_dataset_id"].length +
            thisAudit["all_zombie_surveys_dataset_but_is_gone"].length
              ? thisAudit["all_zombie_surveys_no_dataset_id"].length +
                thisAudit["all_zombie_surveys_dataset_but_is_gone"].length
              : 0
        }
        this.audit.zombie_surveys_loading = false
      }
      if (method == "datasets") {
        params["include_datasets"] = true
        this.audit.zombie_datasets_loading = true
        let thisAudit = await this.DATASETS_SERVICE.findZombieDatasets(params)
        if (deleteZombie) {
          this.audit.zombie_datasets_found = -1
        } else {
          this.audit.zombie_datasets_found =
            thisAudit["all_zombie_dataset_no_file_id"].length +
            thisAudit["all_zombie_dataset_file_id_but_is_gone"].length +
            thisAudit["all_zombie_project_datasets"].length
              ? thisAudit["all_zombie_dataset_no_file_id"].length +
                thisAudit["all_zombie_dataset_file_id_but_is_gone"].length +
                thisAudit["all_zombie_project_datasets"].length
              : 0
        }
        this.audit.zombie_datasets_loading = false
      }
      if (method == "client_questions") {
        params["include_client_questions"] = true
        this.audit.zombie_client_questions_loading = true
        let thisAudit = await this.DATASETS_SERVICE.findZombieDatasets(params)
        if (deleteZombie) {
          this.audit.zombie_client_questions_found = -1
        } else {
          this.audit.zombie_client_questions_found =
            thisAudit["all_zombie_client_question_no_dataset_id"].length +
            thisAudit["all_zombie_client_question_dataset_id_but_is_gone"]
              .length +
            thisAudit["all_zombie_client_question_no_survey_file_id"].length +
            thisAudit["all_zombie_client_question_survey_file_id_but_is_gone"]
              .length
              ? thisAudit["all_zombie_client_question_no_dataset_id"].length +
                thisAudit["all_zombie_client_question_dataset_id_but_is_gone"]
                  .length +
                thisAudit["all_zombie_client_question_no_survey_file_id"]
                  .length +
                thisAudit[
                  "all_zombie_client_question_survey_file_id_but_is_gone"
                ].length
              : 0
        }
        this.audit.zombie_client_questions_loading = false
      }
      if (method == "benchmarks") {
        params["include_benchmarks"] = true
        this.audit.zombie_benchmarks_loading = true
        let thisAudit = await this.DATASETS_SERVICE.findZombieDatasets(params)
        if (deleteZombie) {
          this.audit.zombie_benchmarks_found = -1
        } else {
          this.audit.zombie_benchmarks_found =
            thisAudit["all_zombie_benchmarks_no_client_question_id"].length +
            thisAudit["all_zombie_benchmarks_client_question_id_but_is_gone"]
              .length +
            thisAudit["all_zombie_benchmarks_no_client_data_set_id"].length +
            thisAudit["all_zombie_benchmarks_client_data_set_id_but_is_gone"]
              .length +
            thisAudit["all_zombie_benchmarks_no_global_question_id"].length +
            thisAudit["all_zombie_benchmarks_global_question_id_but_is_gone"]
              .length
              ? thisAudit["all_zombie_benchmarks_no_client_question_id"]
                  .length +
                thisAudit[
                  "all_zombie_benchmarks_client_question_id_but_is_gone"
                ].length +
                thisAudit["all_zombie_benchmarks_no_client_data_set_id"]
                  .length +
                thisAudit[
                  "all_zombie_benchmarks_client_data_set_id_but_is_gone"
                ].length +
                thisAudit["all_zombie_benchmarks_no_global_question_id"]
                  .length +
                thisAudit[
                  "all_zombie_benchmarks_global_question_id_but_is_gone"
                ].length
              : 0
        }
        this.audit.zombie_benchmarks_loading = false
      }
      if (method == "text_responses") {
        params["include_text_responses"] = true
        this.audit.zombie_text_responses_loading = true
        let thisAudit = await this.DATASETS_SERVICE.findZombieDatasets(params)
        if (deleteZombie) {
          this.audit.zombie_text_responses_found = -1
        } else {
          this.audit.zombie_text_responses_found =
            thisAudit["all_zombie_text_responses_no_client_question_id"]
              .length +
            thisAudit[
              "all_zombie_text_responses_client_question_id_but_is_gone"
            ].length +
            thisAudit["all_zombie_text_responses_no_data_set_id"].length +
            thisAudit["all_zombie_text_responses_data_set_id_but_is_gone"]
              .length
              ? thisAudit["all_zombie_text_responses_no_client_question_id"]
                  .length +
                thisAudit[
                  "all_zombie_text_responses_client_question_id_but_is_gone"
                ].length +
                thisAudit["all_zombie_text_responses_no_data_set_id"].length +
                thisAudit["all_zombie_text_responses_data_set_id_but_is_gone"]
                  .length
              : 0
        }
        this.audit.zombie_text_responses_loading = false
      }
      if (method == "text_lemmas") {
        params["include_text_lemmas"] = true
        this.audit.zombie_text_lemmas_loading = true
        let thisAudit = await this.DATASETS_SERVICE.findZombieDatasets(params)
        if (deleteZombie) {
          this.audit.zombie_text_lemmas_found = -1
        } else {
          this.audit.zombie_text_lemmas_found =
            thisAudit["all_zombie_text_lemmas_no_client_question_id"].length +
            thisAudit["all_zombie_text_lemmas_client_question_id_but_is_gone"]
              .length +
            thisAudit["all_zombie_text_lemmas_no_data_set_id"].length +
            thisAudit["all_zombie_text_lemmas_data_set_id_but_is_gone"].length
              ? thisAudit["all_zombie_text_lemmas_no_client_question_id"]
                  .length +
                thisAudit[
                  "all_zombie_text_lemmas_client_question_id_but_is_gone"
                ].length +
                thisAudit["all_zombie_text_lemmas_no_data_set_id"].length +
                thisAudit["all_zombie_text_lemmas_data_set_id_but_is_gone"]
                  .length
              : 0
        }
        this.audit.zombie_text_lemmas_loading = false
      }
      if (method == "sentence_embeddings") {
        params["include_sentence_embeddings"] = true
        this.audit.zombie_sentence_embeddings_loading = true
        let thisAudit = await this.DATASETS_SERVICE.findZombieDatasets(params)
        if (deleteZombie) {
          this.audit.zombie_sentence_embeddings_found = -1
        } else {
          this.audit.zombie_sentence_embeddings_found =
            thisAudit[
              "all_zombie_text_sentence_embeddings_no_client_question_id"
            ].length +
            thisAudit[
              "all_zombie_text_sentence_embeddings_client_question_id_but_is_gone"
            ].length +
            thisAudit["all_zombie_text_sentence_embeddings_no_response_id"]
              .length +
            thisAudit[
              "all_zombie_text_sentence_embeddings_response_id_but_is_gone"
            ].length
              ? thisAudit[
                  "all_zombie_text_sentence_embeddings_no_client_question_id"
                ].length +
                thisAudit[
                  "all_zombie_text_sentence_embeddings_client_question_id_but_is_gone"
                ].length +
                thisAudit["all_zombie_text_sentence_embeddings_no_response_id"]
                  .length +
                thisAudit[
                  "all_zombie_text_sentence_embeddings_response_id_but_is_gone"
                ].length
              : 0
        }
        this.audit.zombie_sentence_embeddings_loading = false
      }
      if (method == "themes") {
        params["include_themes"] = true
        this.audit.zombie_themes_loading = true
        let thisAudit = await this.DATASETS_SERVICE.findZombieDatasets(params)
        if (deleteZombie) {
          this.audit.zombie_themes_found = -1
        } else {
          this.audit.zombie_themes_found = thisAudit[
            "all_zombie_themes_with_no_textanalysisobjects"
          ].length
            ? thisAudit["all_zombie_themes_with_no_textanalysisobjects"].length
            : 0
        }
        this.audit.zombie_themes_loading = false
      }
    }
  },
  watch: {
    revokeAccess: function (val) {
      if (val) {
        this.$router.push({ name: "projects" })
      }
    }
  }
}
</script>
